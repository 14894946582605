import moment from 'moment-timezone';
import { Plan, Product } from 'type/interface';
import { v4 as uuidv4 } from 'uuid';

// Function to generate a random 6-letter string consisting of uppercase letters only
// 240521-215608_korea-roaming1_dp-500MB-5D_421_ABCDEF

export const generateOrderID = (
  productState: Product,
  selectedPlanState: Plan,
) => {
  function generateRandomString(): string {
    return uuidv4().replace(/-/g, '').toUpperCase().slice(0, 6);
  }

  // Generate the random string
  const randomString = generateRandomString();

  // Create the order ID without milliseconds and with all uppercase letters
  const orderID = `${moment().tz('America/Edmonton').format('YYMMDD-HHmmss')}_${
    productState.product_name
  }_${selectedPlanState.id}_${randomString}`;

  return orderID;
};
