import { atom } from 'jotai';
import { Plan, Product } from 'type/interface';

export const productAtom = atom<Product>({
  country: [],
  country_en: '',
  country_kr: '',
  product_name: '',
  plans: [],
});

export const selectedPlanAtom = atom<Plan>({
  name: '',
  SKU: '',
  price: null, // KRW
  categoryId: '',
  capacityId: '',
  days: 0,
  productId: 0,
  productDescription: '',
  unlimiitedCapacity: '',
  carrier: '',
  apn: '',
  speed: '',
  isLocalEsim: false,
  canCallOrText: '', // "", 'text_only", "call_only", "both"
  canActivateBeforeDeparture: false,
  eSimProvider: '', // BC, JOY
  qrValidFor: 0,
  hotspot: false,

  id: '',
  plan: '',
  name_kr: '', //"한국 - 데일리 플랜형 500MB/일 (사용기간: 30일)"
  v_planDescription: '',
});

// // export const selectedPlanAtom = atom((get) => get(productAtom))

// export const useProduct = () => {
//   const productState = useAtom(productAtom);
//   return productState;
// };

// export const useSelectedPlan = () => {
//   const selectedPlanState = useAtom(selectedPlanAtom);
//   return selectedPlanState;
// };

// Selector function to get plan by ID
export const getPlanById = (product: Product, planId: string) => {
  return product.plans.find((plan) => plan?.id === planId);
};
