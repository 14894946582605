/**
 * HONEY ESIM Product Detail Page - ESIM 상품 카드
 */

import { Dialog, Transition } from '@headlessui/react';
import { db, doc, getDoc } from 'api/firebase';
import SupportedDevices from 'containers/PageAddListing1/SupportedDevices';
import { useAtom, useAtomValue } from 'jotai';
import { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Loader from 'shared/Loader/Loader';
import { currencyAtom } from 'store/atoms/currency';
import { productAtom, selectedPlanAtom } from 'store/atoms/product';
import { Plan, Product } from 'type/interface';
import { currencyConversion } from 'utils/currencyConversion';
import MobileFooterSticky from '../(components)/MobileFooterSticky';
import DetailPagetLayout from '../Layout';

const generatPlanDesc = (data: any) => {
  const isDailyPlan = data.categoryId === 'dp';
  const isUnlimited = !!data.unlimiitedCapacity;

  if (isDailyPlan && isUnlimited) {
    return `매일 ${data.capacityId} 사용 후 ${data.unlimiitedCapacity} 속도로 무제한`;
  } else if (!isDailyPlan && isUnlimited) {
    return `${data.days}일 동안 총 ${data.capacityId}의 데이터 사용 후 ${data.unlimiitedCapacity} 속도로 무제한`;
  } else if (isDailyPlan && !isUnlimited) {
    return `매일 ${data.capacityId}의 한정된 데이터 사용 가능`;
  } else {
    return `${data.days}일 동안 총 ${data.capacityId}의 한정된 데이터 사용 가능`;
  }
};

const StayDetailPageContainer: FC<{}> = () => {
  const { productName, planId } = useParams();
  const navigate = useNavigate();

  const currencyState = useAtomValue(currencyAtom);
  const [productState, setProductState] = useAtom(productAtom);
  const [selectedPlanState, setSelectedPlanState] = useAtom(selectedPlanAtom);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const docRef = doc(db, 'PRODUCTS', productName as string);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          // Update the product state without causing a re-render
          setProductState((prevProductState) => {
            const newData = docSnapshot.data() as Product;
            if (prevProductState !== newData) {
              sessionStorage.setItem('productState', JSON.stringify(newData));
              return newData;
            } else {
              return prevProductState;
            }
          });
        } else {
          navigate('/404');
        }
      } catch (error) {}
      setIsLoading(false);
    };

    if (productState.country.length === 0) {
      const storedProductState = sessionStorage.getItem('productState');
      // if product state exists in session storage,
      if (storedProductState) {
        const parsedStoredProductState = JSON.parse(storedProductState);
        // and product name matches the url param,
        if (parsedStoredProductState.product_name === productName) {
          // set product state
          setProductState(parsedStoredProductState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('productState');
          navigate('/404');
        }
      } else {
        // If product state is not found in session storage, fetch it from Firestore
        fetchData();
      }
    }
  }, [productName, navigate, productState, setProductState]);

  useEffect(() => {
    // if product state exists but not selectedPlanstate
    if (productState.country.length && !selectedPlanState.id) {
      // first check session storage
      const storedSelectedPlanState =
        sessionStorage.getItem('selectedPlanState');
      // if storedSelectedPlanState exists
      if (storedSelectedPlanState) {
        const parsedStoredSelectedPlanState = JSON.parse(
          storedSelectedPlanState,
        );
        // check if id matches
        if (parsedStoredSelectedPlanState.id === planId) {
          // then set selected plan state
          setSelectedPlanState(parsedStoredSelectedPlanState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('selectedPlanState');
          navigate('/404');
        }
      } else {
        // if not in session storage, find it from product state
        const plan = productState.plans.find((d) => d.id === planId);
        if (!plan) {
          navigate('/404');
        }
        setSelectedPlanState(plan as Plan);
        sessionStorage.setItem('selectedPlanState', JSON.stringify(plan));
      }
    }
  }, [setSelectedPlanState, selectedPlanState, productState, navigate, planId]);

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const notes = [
    {
      name: `${
        selectedPlanState.canActivateBeforeDeparture
          ? '이 상품은 출국 전 eSIM 설치가 가능합니다'
          : '이 상품은 eSIM 설치 후 바로 사용일수가 차감됩니다. 현지에서 설치하시길 권장합니다'
      }`,
    },
    {
      name: `이 상품의 QR코드 유효기간은 ${selectedPlanState.qrValidFor}일 이며, 기간 내에 eSIM 설치 및 현지 네트워크 접속이 이루어져야 합니다`,
    },
    {
      name: 'QR코드 유효기간과 사용일수는 별개이며, 남은 유효기간에 상관없이 사용일수만큼 사용하실 수 있습니다',
    },
    { name: 'eSIM 설치 후 기기변경이 불가합니다' },
    { name: '네트워크가 연결된 장소에서만 eSIM 설치가 가능합니다' },
  ];

  const renderEsimSupportedDevice = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalAmenities}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full max-w-4xl'>
                <div className='inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      eSIM 지원 기기
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className='px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <SupportedDevices />

                    {/* {supported_devices.map((item, i) => (
                      <div
                        key={i}
                        className='flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8'
                      >
                        <span
                          className={`text-xs sm:text-md text-neutral-2000`}
                        >
                          {item.brand}
                        </span>
                        <span className={`text-xs sm:text-sm`}>
                          {item.device}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className='my-2 px-8 text-neutral-700 dark:text-neutral-300 text-center'></div> */}

                    {/* <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 중국에서 출시된 아이폰은 지원되지 않습니다
                  </span>
                  <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 홍콩,마카오에서 출시된 아이폰은 지원되지 않습니다 (예외 -
                    iPhone 13 mini, iPhone 12 mini, iPhone SE 2020, iPhone XS)
                  </span>
                  <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 한국에서 출시된 다음 갤럭시 모델은 지원되지 않습니다
                    (Galaxy S20, Galaxy S20+ 5G, Galaxy S20 Ultra, Galaxy S20
                    Ultra 5G, Galaxy S21, Galaxy S21+ 5G, Galaxy S21 Ultra 5G,
                    Galaxy S22, Galaxy S22+, Galaxy S22 Ultra, Galaxy Note 20
                    Ultra 5G, Galaxy Note 20, Galaxy Fold, Galaxy Z Fold2 5G,
                    Galaxy Z Fold3 5G, Galaxy Z Flip, Galaxy Z Flip 5G, Galaxy Z
                    Flip3 5G)
                  </span>
                  <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 그 밖의 다른 iOS, 안드로이드 기기는 *#06#
                    (별표-샾-0-6-샾)번호를 눌러서 EID 정보가 나오면 가능합니다.
                    출시국가 및 모델에 따라 다를 수 있으니 자세한 사항은
                    제조사에 먼저 문의 바랍니다
                  </span> */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    selectedPlanState && (
      <div className='nc-EsimPlandDetail'>
        {/* MAIN */}
        <main className=' relative z-10 mt-11 flex flex-col lg:flex-row pb-20'>
          {/* CONTENT */}
          <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
            {isLoading ? (
              <Loader counts={7} />
            ) : (
              <>
                <div className='listingSection__wrap !space-y-6'>
                  {/* 헤더 제목 및 간단한 설명 */}
                  <div className='flex items-start'>
                    {selectedPlanState?.unlimiitedCapacity && (
                      <Badge name='데이터 무제한' className='mr-1' />
                    )}
                    {selectedPlanState?.canCallOrText === 'call_only' && (
                      <Badge color='yellow' name='현지 전화가능' />
                    )}
                    {selectedPlanState?.canCallOrText === 'text_only' && (
                      <Badge color='yellow' name='현지 문자가능' />
                    )}
                    {selectedPlanState?.isLocalEsim && (
                      <Badge color='red' name='로컬심' />
                    )}
                    {selectedPlanState?.unlimiitedCapacity.includes('mbps') && (
                      <Badge color='red' name='초고속 무제한' />
                    )}
                  </div>
                  <div className='text-2xl sm:text-3xl lg:text-4xl font-semibold ml-2'>
                    {selectedPlanState?.name_kr}
                    {/* <span className="text-2xl text-neutral-700 dark:text-neutral-200 font-medium">(1GB/일)</span> */}
                  </div>
                  <div className='flex items-center'>
                    <span className='ml-2.5 text-neutral-500 dark:text-neutral-400'>
                      {generatPlanDesc(selectedPlanState)}
                    </span>
                  </div>

                  <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-2 text-sm text-neutral-700 dark:text-neutral-300'>
                    <div className='flex items-center space-x-1 '>
                      <i className='las la-broadcast-tower text-2xl text-primary-600'></i>
                      <span className=' md:inline-block text-neutral-500'>
                        통신사:
                      </span>
                      <span className='font-medium text-base'>
                        {selectedPlanState.carrier}
                      </span>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <i className='las la-calendar text-2xl text-primary-600'></i>
                      <span className=' md:block text-neutral-500'>
                        사용기간:
                      </span>
                      <span className='font-medium text-base'>
                        {selectedPlanState?.days}일
                      </span>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <i className=' las la-rocket text-2xl text-primary-600'></i>
                      <span className=' md:block text-neutral-500'>속도:</span>
                      <span className='font-medium text-base'>
                        {selectedPlanState.speed}
                      </span>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <i className=' las la-qrcode text-2xl text-primary-600'></i>
                      <span className=' md:block text-neutral-500'>
                        QR코드 유효기간:
                      </span>
                      <span className='font-medium text-base'>
                        {selectedPlanState.qrValidFor}일
                      </span>
                    </div>

                    <div className='flex items-center space-x-1'>
                      {selectedPlanState.canCallOrText ? (
                        <i className=' las la-phone-alt text-2xl'></i>
                      ) : (
                        <i className=' las la-phone-slash text-2xl text-primary-600'></i>
                      )}
                      <span className=' md:block text-neutral-500'>
                        현지 전화/문자:
                      </span>
                      <span className='font-medium text-base'>
                        {selectedPlanState.canCallOrText
                          ? selectedPlanState.canCallOrText
                          : '불가'}
                      </span>
                    </div>

                    <div className='flex items-center space-x-1'>
                      <i className=' las la-wifi text-2xl text-primary-600'></i>
                      <span className=' md:block text-neutral-500'>
                        핫스팟:
                      </span>
                      <span className='font-medium text-base'>
                        {selectedPlanState.hotspot ? '가능' : '불가'}
                      </span>
                    </div>
                  </div>
                </div>{' '}
                <div className='listingSection__wrap'>
                  <div>
                    <h2 className='text-2xl font-semibold'>
                      {' '}
                      ✅ 구매하시기 전에
                    </h2>
                    <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
                      꼭 읽어주세요
                    </span>
                  </div>
                  <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                  {/* 6 */}
                  <div className='grid grid-cols-1 gap-3 text-neutral-700 dark:text-neutral-300'>
                    {notes
                      .filter((_, i) => i < 12)
                      .map((item) => (
                        <div
                          key={item.name}
                          className='flex items-center space-x-3'
                        >
                          <i className='las la-check-circle text-2xl text-primary-600'></i>
                          <span className='text-sm sm:text-base'>
                            {item.name}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='listingSection__wrap'>
                  {/* HEADING */}
                  <h2 className='text-2xl font-semibold'>
                    ❗eSIM 사용 유의사항
                  </h2>
                  <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

                  {/* CONTENT */}
                  <div>
                    <h4 className='text-lg font-semibold'>
                      eSIM 지원 기기 및 컨트리락 확인
                    </h4>
                    <div className='prose sm:prose'>
                      <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                        <li>컨트리락이 해제된 기기인지 확인해주세요</li>
                        <li>eSIM 서비스가 가능한 기기인지 확인해주세요</li>
                        <li>
                          같은 제조사, 모델명이라도 일부 국가에서 출시된 기기인
                          경우 eSIM이 지원 안되는 경우가 있습니다
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <ButtonSecondary
                      onClick={openModalAmenities}
                      className='border-primary-400'
                    >
                      eSIM 지원 단말기 확인 하기
                    </ButtonSecondary>
                  </div>
                  {renderEsimSupportedDevice()}

                  {/* CONTENT */}
                  <div>
                    <h4 className='text-lg font-semibold'>사용기간</h4>
                    <div className='prose sm:prose'>
                      <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                        <li>
                          eSIM의 사용일은 한국시간 새벽 1시 기준이며, 데일리
                          플랜의 경우 데이터가 새로 제공됩니다
                        </li>
                        <li>
                          예시로 데일리 플랜형 10일 eSIM을 기기에 설치 후 현지에
                          도착하면 다음과 같이 계산됩니다
                        </li>
                      </ul>
                    </div>
                    <div className='mt-3 sm:ml-8 sm:mr-28 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base mx-auto'>
                      <div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
                        <span>현지 네트워크 로밍 활성화</span>
                        <span>3월 1일 오전 10시 (한국)</span>
                      </div>
                      <div className='flex space-x-10 justify-between p-3'>
                        <span>데이터 서비스 종료</span>
                        <span>3월 11일 오전 1시 (한국)</span>
                      </div>
                    </div>
                  </div>

                  {/* CONTENT */}
                  <div>
                    <h4 className='text-lg font-semibold'>
                      네트워크 및 통신속도
                    </h4>
                    <div className='prose sm:prose'>
                      <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                        <li>
                          사용하시고 계신 기기 혹은 현지 통신사의 사정에 따라서
                          LTE가 아닌 3G로 연결될 수 있습니다
                        </li>
                        <li>
                          로밍서비스를 이용하기에 해당국가에 따라 간혹 서비스가
                          안되는 앱이 있을 수 있습니다
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* CONTENT */}
                  <div>
                    <h4 className='text-lg font-semibold'>환불</h4>
                    <div className='prose sm:prose'>
                      <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                        <li>구매 후 14일 전까지 QR코드 스캔이 되지 않았거나</li>
                        <li>제품에 문제가 있을 경우 환불 가능합니다</li>
                      </ul>
                    </div>
                    <div className='mt-4 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                      아래의 경우 환불이 불가능합니다.
                    </div>
                    <div className='prose sm:prose'>
                      <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                        <li>
                          현지국가 통신사의 네트워크 사정에 의한 서비스 제한
                        </li>
                        <li>
                          이미 QR코드가 스캔되어 eSIM이 활성화 되어 있는 경우
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{' '}
              </>
            )}
          </div>

          {/* 가격정보 */}
          <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
            {isLoading ? (
              <Loader counts={2} />
            ) : (
              <>
                <div className='sticky top-28'>
                  <div className='listingSectionSidebar__wrap shadow-xl'>
                    {/* SUM */}
                    <div className='flex flex-col space-y-4'>
                      <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
                        <span className='text-xl'>
                          {selectedPlanState?.name_kr}
                        </span>
                      </div>
                      <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
                        {/* <span>1GB/일 + 128kbps</span> */}
                      </div>
                    </div>
                    {/* PRICE */}
                    <div className='flex justify-between'>
                      <span className='text-3xl font-semibold ml-auto'>
                        {currencyConversion(
                          selectedPlanState?.price as number,
                          currencyState,
                        )}
                        <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                          {currencyState}
                        </span>
                      </span>
                    </div>

                    {/* FORM */}
                    {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput className="flex-1 z-[11]" />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1" />
        </form> */}

                    {/* SUBMIT */}
                    <ButtonPrimary href={`/checkout/${productName}/${planId}`}>
                      <span className='text-lg font-semibold'>구매하기</span>
                    </ButtonPrimary>
                  </div>
                </div>
              </>
            )}
          </div>
        </main>

        {/* STICKY FOOTER MOBILE */}
        <MobileFooterSticky />
      </div>
    )
  );
};

export default function EsimPlandDetail() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
