import FAQSearch from 'components/HeroSearchForm/FAQSearch';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Accordion = () => {
  const accordionItems = [
    {
      header: 'eSIM을 설치했지만 인터넷 연결이 되지 않아요',
      text: 'eSIM이 정상적으로 설치 되었다면 eSIM 데이터 로밍 활성화가 되어 있는지 확인 부탁드립니다. ',
    },
    {
      header: "QR코드 스캔 시 '셀룰러 요금제를 추가할 수 없음' 메시지가 떠요",
      text: '컨트리락이 되어있거나 이미 eSIM이 설치되어 있는 경우가 많습니다.',
      // src: 'https://image.simgonow.com/LnInSBCVRyKu.png',
    },
    {
      header:
        "QR코드 스캔 시 '셀룰러 요금제 변경을 완료할 수 없음' 메시지가 떠요",
      text: '와이파이 혹은 데이터 통신이 되는 곳인지 확인 바랍니다.',
    },
    {
      header: 'QR코드 스캔이 잘 안돼요',
      text: 'QR코드 스캔이 어려운 경우 보내드린 이메일에 나와있는 코드로 수동설치도 가능합니다.',
      href: '/user-guide/setup',
      href_text: '설치 바로가기',
    },
    {
      header: 'LTE, 5G가 아닌 3G, 2G로 떠요',
      text: '현지 네트워크 혹은 사용하시는 기기에 따라서 지원되는 데이터 속도가 다를 수 있습니다.',
    },
    {
      header: '다른 기기에 같은 eSIM을 사용할 수 있나요?',
      text: '유심과 다르게 다른 기기에서는 사용이 불가능하고 최초로 eSIM이 등록된 기기에만 사용가능합니다.',
    },
    {
      header: '사용 날짜는 어떻게 계산되나요?',
      text: '한국시각으로 새벽 1시에 사용일수가 계산되고 데이터가 리셋됩니다. 1월 1일 오후 1시(한국시간 기준)에 현지 국가 네트워크 접속이 되면 다음 날 1월 2일 새벽 1시 (한국시간 기준)가 2일차가 됩니다.',
    },
    {
      header: 'QR코드 유효기간이 뭔가요?',
      text: '유효기간 안에 기기에 eSIM 설치 및 현지 국가에서 네트워크 접속이 이루어져야 eSIM으로 데이터를 사용할 수 있습니다. 실제 사용일수와 무관합니다.',
    },
    {
      header: 'eSIM을 삭제해도 될까요?',
      text: '한 번 삭제한 eSIM은 재사용이 불가능합니다. 여행 후 더 이상 필요가 없는 경우가 아니라면 삭제하시면 안됩니다.',
    },
    {
      header: '사용을 안했는데 환불이 가능할까요?',
      text: '디지털 상품 특성상 음원을 다운로드 받은 경우와 마찬가지로 한 번 구매된 eSIM은 환불이 어렵습니다.',
    },
    {
      header: '현지 인터넷 속도가 많이 느려요',
      text: '현지 네트워크 사정에 따라서 속도가 빠르거나 느려질 수 있습니다.',
    },
  ];

  const [faqItems, setFaqItems] = useState<any>();

  useEffect(() => {
    setFaqItems(accordionItems);
  }, []);

  return (
    <>
      {' '}
      <Helmet>
        <meta charSet='UTF-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <section className='relative z-20 overflow-hidden bg-white pt-5 sm:pb-12 sm:pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px] mb-2 h-max'>
        <div className='container mx-auto'>
          <div className='-mx-4 flex flex-wrap h-10 sm:h-40'>
            <div className='w-full px-4'>
              <div className='mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20'>
                <span className='text-base sm:mb-2 block sm:text-4xl font-semibold text-primary'>
                  FAQ
                </span>
                <h2 className='hidden sm:inline-block mb-4 text-2xl font-semibold text-dark dark:text-white sm:text-[40px]/[48px]'>
                  문제가 발생했거나
                  <div>궁금한 사항이 있으신가요?</div>
                </h2>
                {/* <p className='text-base text-body-color dark:text-dark-6'>
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p> */}
              </div>
            </div>
          </div>
          <div className='flex justify-center'>
            <div className='flex relative sm:mt-8 rounded-full shadow-sm dark:shadow-2xl bg-white dark:bg-neutral-800 mb-10 w-full lg:w-1/2 border-primary-400 border'>
              <FAQSearch
                className='flex-[1.5]'
                setFaqItems={setFaqItems}
                accordionItems={accordionItems}
              />
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 sm:gap-4 gap-2'>
            {faqItems?.map((s: any, i: number) => (
              <AccordionItem key={i} {...s} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Accordion;

const AccordionItem = ({ header, text, src, href, href_text }: any) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div
      onClick={() => handleToggle()}
      className='w-full rounded-lg bg-white p-2 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8 hover:border-primary-400 hover:border mb-1'
    >
      <button className={`faq-btn flex w-full text-left`}>
        <div className='w-full flex items-center'>
          {' '}
          {/* Apply items-center here */}
          <div className='mr-5 flex h-10 max-w-[40px] items-center justify-center rounded-lg text-primary dark:bg-white/5'>
            <svg
              className={`fill-primary stroke-primary duration-200 ease-in-out ${
                active ? 'rotate-180' : ''
              }`}
              width='17'
              height='10'
              viewBox='0 0 17 10'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z'
                fill=''
                stroke=''
              />
            </svg>
          </div>
          <div className='flex items-center'>
            {' '}
            {/* Apply items-center here */}
            <h4 className='mt-1 text-sm sm:text-lg font-semibold text-dark dark:text-white'>
              {header}
            </h4>
          </div>
        </div>
      </button>

      <div
        className={`duration-200 ease-in-out ${active ? 'block' : 'hidden'}`}
      >
        <div>
          <p className='text-sm sm:text-base py-3 leading-relaxed text-body-color dark:text-dark-6'>
            {text}
          </p>
        </div>
        {src && (
          <div>
            <img src={src} alt='' />
          </div>
        )}
        {href && href_text && (
          <Link to={href}>
            <span className='text-primary-600'>{href_text}</span>
          </Link>
        )}
      </div>
    </div>
  );
};
