import G_BD_5 from 'images/user-guide/setup/G_BD_5.png';
import G_M_1 from 'images/user-guide/setup/G_M_1.png';

import CommonLayout from './CommonLayout';

export default function AAgalaxy() {
  return (
    <CommonLayout
      index='01'
      backtHref=''
      nextHref='/user-guide/setup/aa-iphone-2'
    >
      <>
        <div className='pt-10'>
          <div className='mb-3'>
            QR 코드 스캔이 되지 않으면 "활성화 코드"를 직접 입력합니다
          </div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 1. '}
            <span className='text-bold text-black'>
              {'QR 코드 스캔 화면에서 아래 "활성화 코드 입력"'}
            </span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_5} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 2. '}
            <span className='text-bold text-black'>
              {'결제 후 받은 이메일에서 활성화 코드 복사 후 직접입력'}
            </span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_M_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>
      </>
    </CommonLayout>
  );
}
