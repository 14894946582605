import { atom, useAtom } from 'jotai';

// currency can be set in the header doprdown

export const currencyAtom = atom<string>('USD');

export const useCurrency = () => {
  const currencyState = useAtom(currencyAtom);
  return currencyState;
};
