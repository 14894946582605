/**
 * HONEY ESIM - what is esim
 */

import { FC } from 'react';
import CommonLayout from './CommonLayout';

export interface WhatIsEsimProps {}

const notes = [
  {
    name: `국제로밍 편의성: 여행 중에 국제로밍 서비스를 이용할 때, 실제 SIM 카드를 교체할 필요 없이 디바이스 설정에서 쉽게 네트워크를 변경할 수 있습니다.`,
  },
  {
    name: '여러 네트워크 프로필 저장: eSIM은 여러 개의 네트워크 프로필을 저장하고 필요에 따라 손쉽게 전환할 수 있습니다.',
  },
  {
    name: '안전성: 물리적인 SIM 카드는 분실되거나 도난당할 경우에 대비해 보안에 취약할 수 있지만, eSIM은 디바이스 내에 저장되어 있어 보안이 강화될 수 있습니다.',
  },
  {
    name: '편리한 설정: 새로운 디바이스를 사용할 때, 실제 SIM 카드를 끼우는 번거로움 없이 eSIM을 사용하여 네트워크에 연결할 수 있습니다.',
  },
];

const WhatIsEsim: FC<WhatIsEsimProps> = () => {
  return (
    <CommonLayout>
      <>
        <div className='p-4 md:p-10'>
          <h2 className='text-2xl font-semibold mb-8'>eSIM은 무엇인가요?</h2>
          <div className='block mt-2 text-neutral-700 dark:text-neutral-400'>
            "eSIM"이란 "embedded Subscriber Identity Module"의 약자로, 실제 SIM
            카드가 아니라 디바이스에 내장된 가상 SIM 카드를 의미합니다.
            <div className='my-10'></div>
            <div>
              일반적인 SIM 카드는 우리가 휴대폰에 넣는 작은 카드와 같이 물리적인
              형태를 가지고 있는 반면, eSIM은 마치 핸드폰 기기의 소프트웨어 같은
              성격을 가지고 있습니다. 이것을 비유로 설명하자면, 마치 일반적인
              유심은 신용카드 그리고 eSIM은 애플페이나 삼성페이 같은
              소프트웨어로 말할 수 있습니다. 둘다 결제의 기능을 가지지만
              신용카드는 쉽게 잊어버리고 도난 및 복제 등 악용될 수 있는 반면
              삼성페이는 지문, 비밀번호로 안전하게 보호되어 있죠. 이와
              마찬가지로 유심도 쉽게 복제 되어 악용될 수 있지만 eSIM은 쉽게 복제
              및 해킹하기가 어렵습니다.
            </div>
            <div className='my-10'></div>
            <div>
              또한 유심은 물리적으로 교체가 가능한 카드이기 때문에 여러 장치에서
              사용할 수 있고, 필요에 따라 교체할 수 있습니다. 반면에 eSIM은
              내장형이기 때문에 디바이스와 밀접하게 연결되어 있어 교체가
              어렵습니다.
            </div>
            <div className='my-10'></div>
            <h2 className='text-2xl text-black font-semibold'>
              eSIM이 유심보다 좋은 이유
            </h2>
            <div className='my-10'></div>
            <div className='grid grid-cols-1 gap-6 text-neutral-700 dark:text-neutral-300'>
              {notes
                .filter((_, i) => i < 12)
                .map((item) => (
                  <div key={item.name} className='flex items-center space-x-3'>
                    <i className='las la-check-circle text-2xl text-primary-600'></i>
                    <span className='sm:text-base'>{item.name}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default WhatIsEsim;
