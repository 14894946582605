/**
 * HONEY ESIM 메인 페이지 검색창
 */

'use client';

import { FC } from 'react';
import StaySearchForm from './(stay-search-form)/StaySearchForm';

export type SearchTab = 'Stays' | 'Experiences' | 'Cars' | 'Flights';

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: 'Stays' | 'Experiences' | 'Cars' | 'Flights';
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      {/* {renderTab()} */}
      <StaySearchForm />
    </div>
  );
};

export default HeroSearchForm;
