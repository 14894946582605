/**
 * HONEY ESIM Product Page - South Korea
 */
import { Tab } from '@headlessui/react';
import FlightCard from 'components/FlightCard/FlightCard';
import Heading2 from 'components/Heading/Heading2';
import { useAtomValue } from 'jotai';
import { FC, Fragment, useState } from 'react';
import { productAtom } from 'store/atoms/product';
import { Plan } from 'type/interface';

export interface SectionGridFilterCardProps {
  className?: string;
}

interface CapacityType {
  id: number;
  name: string;
}

interface TabType {
  id: string;
  name: string;
}

const CATEGORY_TABS: TabType[] = [
  { id: 'dp', name: '데일리 플랜형' },
  { id: 'dc', name: '데이터 용량형' },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface TabPanelCustomProps {
  children?: React.ReactNode;
}
const TabPanelCustom: FC<TabPanelCustomProps> = ({ children }) => {
  return <Tab.Panel className='mt-8'>{children}</Tab.Panel>;
};

function createCapacityObjects(plans: Plan[]) {
  const DAILY_PLAN: CapacityType[] = [];
  const DATA_CAPACITY: CapacityType[] = [];

  const uniqueCapacities = (plansArray: Plan[], categoryId: string) => {
    return Array.from(
      new Set(
        plansArray
          .filter((plan) => plan.categoryId === categoryId)
          .map((plan) => plan.capacityId)
      )
    )
      .sort((a, b) => {
        const convertToMB = (capacity: string) =>
          capacity.includes('GB')
            ? parseInt(capacity) * 1024
            : parseInt(capacity);
        return convertToMB(a) - convertToMB(b);
      })
      .map((capacity) => {
        return {
          id: capacity.includes('MB')
            ? parseInt(capacity)
            : parseInt(capacity) * 1024,
          name: capacity,
        };
      });
  };

  const dailyPlanCapacities = uniqueCapacities(plans, 'dp');
  const dataCapacityCapacities = uniqueCapacities(plans, 'dc');

  DAILY_PLAN.push(...dailyPlanCapacities);
  DATA_CAPACITY.push(...dataCapacityCapacities);

  return { DAILY_PLAN, DATA_CAPACITY };
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
}) => {
  const [selectedTabId, setSelectedTabId] = useState('dp');
  const productState = useAtomValue(productAtom);

  const { DAILY_PLAN, DATA_CAPACITY } = createCapacityObjects(
    productState.plans
  );

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id='SectionGridFilterCard'
    >
      <Heading2
        heading={productState.country_kr} // 국가명
        subHeading={
          <span className='text-neutral-400 text-sm'>
            총{' '}
            <span className='text-neutral-500 text-base'>
              {productState.plans.length}
            </span>
            개의 상품
          </span>
        }
      />

      {/* 탭 - 데이터 무제한 | 데이터 종량제 */}
      <div>
        <div className='block'>
          <div className='border-b border-gray-200'>
            <nav className='-mb-px flex' aria-label='Tabs'>
              {CATEGORY_TABS.map((tab) => (
                <div
                  key={tab.name}
                  className={classNames(
                    tab.id === selectedTabId
                      ? 'border-primary-500 text-primary-600 flex-grow lg:flex-grow-0 cursor-pointer	'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium flex-grow lg:flex-grow-0 cursor-pointer	'
                  )}
                  onClick={() => setSelectedTabId(tab.id)}
                >
                  <span className='text-xl font-semibold'> {tab.name}</span>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div>
        <Tab.Group>
          <Tab.List className='flex space-x-1 overflow-x-auto mt-4'>
            {selectedTabId === 'dp'
              ? DAILY_PLAN.length === 0
                ? [
                    <div key={1} className='text-neutral-600 ml-4'>
                      상품이 존재하지 않습니다 😔
                    </div>,
                  ]
                : DAILY_PLAN.map((item) => (
                    <Tab key={item.id} as={Fragment}>
                      {({ selected }) => (
                        <button
                          className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                            selected
                              ? 'bg-secondary-900 text-secondary-50 '
                              : 'bg-neutral-100 text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                          } `}
                        >
                          {item.name}
                        </button>
                      )}
                    </Tab>
                  ))
              : DATA_CAPACITY.length === 0
              ? [
                  <div key={1} className='text-neutral-600 ml-4'>
                    상품이 존재하지 않습니다 😔
                  </div>,
                ]
              : DATA_CAPACITY.map((item) => (
                  <Tab key={item.id} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                          selected
                            ? 'bg-secondary-900 text-secondary-50 '
                            : 'bg-neutral-100 text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                        } `}
                      >
                        {item.name}
                      </button>
                    )}
                  </Tab>
                ))}
          </Tab.List>
          <Tab.Panels>
            {(selectedTabId === 'dp' ? DAILY_PLAN : DATA_CAPACITY).map((s) => (
              <TabPanelCustom key={s.id}>
                {/* 상품 카드 */}
                <div className='lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-2 rounded-3xl overflow-hidden'>
                  {productState.plans
                    .filter(
                      (p) =>
                        p.capacityId === s.name &&
                        p.categoryId === selectedTabId
                    )
                    .map((item, index) => (
                      <FlightCard key={index} data={item} />
                    ))}
                </div>
              </TabPanelCustom>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
