/**
 * HONEY ESIM 메인 꿀이심 소개
 */

import rightImgDemo from 'images/BecomeAnAuthorImg.png';
import { FC } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = '',
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id='SectionBecomeAnAuthor'
    >
      <div className='flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5 pl-2'>
        {/* <Logo className="w-20" /> */}
        <h1 className='font-semibold text-3xl sm:text-5xl mt-6 sm:mt-11'>
          환불, 걱정마세요
        </h1>
        <span className='block mt-6 text-neutral-600 dark:text-neutral-400'>
          <div className='mb-3 text-xl'>
            eSIM 사용이 처음이셔서 잘 될지 걱정되시나요?
          </div>
          <div>
            구매 후 14일까지 사용하지 않으셨거나, 제품에 문제가 있을 경우
          </div>
          <div>전액 환불해드립니다. 안심하고 구매하세요! </div>

          <div>
            환불정책도 완전 <span className='font-bold text-lg'>꿀이심</span>
          </div>
        </span>
        <div className='mt-6 sm:mt-11'></div>
        <ButtonPrimary className={'bg-neutral-100'} href='/products'>
          구매 가능한 eSIM 상품 보기
        </ButtonPrimary>
      </div>
      <div className='flex-grow hidden md:block'>
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
