import I_BD_1 from 'images/user-guide/setup/I_BD_1.png';
import I_BD_10 from 'images/user-guide/setup/I_BD_10.png';
import I_BD_11 from 'images/user-guide/setup/I_BD_11.png';
import I_BD_12 from 'images/user-guide/setup/I_BD_12.png';
import I_BD_13 from 'images/user-guide/setup/I_BD_13.png';
import I_BD_2 from 'images/user-guide/setup/I_BD_2.png';
import I_BD_3 from 'images/user-guide/setup/I_BD_3.png';
import I_BD_4 from 'images/user-guide/setup/I_BD_4.png';
import I_BD_5 from 'images/user-guide/setup/I_BD_5.png';
import I_BD_6 from 'images/user-guide/setup/I_BD_6.png';
import I_BD_7 from 'images/user-guide/setup/I_BD_7.png';
import I_BD_8 from 'images/user-guide/setup/I_BD_8.png';
import I_BD_9 from 'images/user-guide/setup/I_BD_9.png';

import CommonLayout from './CommonLayout';

export default function BDiphone() {
  return (
    <CommonLayout index='01'>
      <>
        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 1. '}
            <span className='text-bold text-black'>{'설정 > 셀룰러'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 2. '}
            <span className='text-bold text-black'>{'eSIM 추가'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_2} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 3. '}
            <span className='text-bold text-black'>{'QR 코드 사용'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_3} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 4. '}
            <span className='text-bold text-black'>
              {'이메일로 받은 QR코드 스캔'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_4} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 5. '}
            <span className='text-bold text-black'>{'eSIM 활성화 > 계속'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_5} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 6. '}
            <span className='text-bold text-black'>{'활성화 중'}</span>
            <div className='text-sm text-slate-800'>
              eSIM 제조사가 홍콩이기에 중문이 표시됩니다
            </div>
            <div className='text-sm text-slate-800'>
              약 30초-1분의 시간이 소요됩니다
            </div>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_6} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 7. '}
            <span className='text-bold text-black'>{'팝업이 뜨면 승인'}</span>
            <div className='text-sm text-slate-800'>
              제조사마다 Billion Connect가 아닌 다른 이름일 수 있습니다
            </div>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_7} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 8. '}
            <span className='text-bold text-black'>
              {'새로운 eSIM 레이블 > "여행용"으로 설정'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_8} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 9. '}
            <span className='text-bold text-black'>
              {'기본회선: "메인"으로 설정'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_9} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 10. '}
            <span className='text-bold text-black'>
              {'iMessage, FaceTime: "메인"으로 설정'}
            </span>
            <div className='text-sm text-slate-800'>
              여행중에도 아이메시지를 받을 수 있습니다
            </div>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_10} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 11. '}
            <span className='text-bold text-black'>
              {'셀룰러 데이터: "메인"으로 설정'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_11} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 12. '}
            <span className='text-bold text-black'>
              {'상단 듀얼 시그널 확인 및 eSIM 여행용 설정'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_12} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 13. '}
            <span className='text-bold text-black'>
              {'여행용 eSIM 회선 "끄기"'}
            </span>
            <div className='text-sm text-slate-800'>
              다시 여행용 eSIM으로 들어가서 비활성화 시킵니다
            </div>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_13} className='w-4/5 sm:w-2/3' />
          </div>
        </div>
      </>
    </CommonLayout>
  );
}
