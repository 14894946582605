import { FC } from 'react';

export interface LaderProps {
  counts?: number;
}

const Loader: FC<LaderProps> = ({ counts = 4 }) => {
  let newArray = Array.from({ length: counts }, (_, index) => index);

  return (
    <div className='grid grid-cols-1 gap-6 mt-4 w-full'>
      {newArray.map((s) => (
        <div
          className='relative p-4 w-full bg-white overflow-hidden shadow hover:shadow-md rounded-lg'
          key={s}
        >
          <div className='animate-pulse flex space-x-4'>
            <div className='flex-1 space-y-4 py-1'>
              <div className='h-4 bg-gray-200 rounded w-3/4'></div>
              <div className='space-y-2'>
                <div className='h-4 bg-gray-200 rounded'></div>
                <div className='h-4 bg-gray-200 rounded w-5/6'></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Loader;
