import G_AA_3 from 'images/user-guide/setup/G_AA_3.png';
import G_AA_4 from 'images/user-guide/setup/G_AA_4.png';
import G_AA_5 from 'images/user-guide/setup/G_AA_5.png';
import G_BD_1 from 'images/user-guide/setup/G_BD_1.png';
import G_BD_2 from 'images/user-guide/setup/G_BD_2.png';

import CommonLayout from './CommonLayout';

export default function AAgalaxy() {
  return (
    <CommonLayout
      index='01'
      backtHref=''
      nextHref='/user-guide/setup/aa-iphone-2'
    >
      <>
        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 1. '}
            <span className='text-bold text-black'>{'설정 > 연결'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 2. '}
            <span className='text-bold text-black'>{'SIM 관리자'}</span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_2} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 3. '}
            <span className='text-bold text-black'>
              {'미리 설치된 eSIM 활성화'}
            </span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_AA_3} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 4. '}
            <span className='text-bold text-black'>{'기존 SIM 끄기'}</span>
            <div className='text-sm text-slate-600'>
              기존 SIM을 켜두면 통신사에 따라 로밍 요금이 발생할 수 있습니다
            </div>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_AA_4} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 5. '}
            <span className='text-bold text-black'>
              {'설정 > 연결 > 데이터 로밍 활성화'}
            </span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_AA_5} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 6. '}
            <span className='text-bold text-black'>
              {'귀국 후 데이터 로밍 비활성화 및 기존 SIM 활성화'}
            </span>
          </h2>{' '}
        </div>
      </>
    </CommonLayout>
  );
}
