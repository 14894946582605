import React, { FC } from 'react';
import Textarea from 'shared/Textarea/Textarea';
import CommonLayout from './CommonLayout';
import FAQ from './FAQ';

export interface PageAddListing6Props {}

const PageAddListing6: FC<PageAddListing6Props> = () => {
  return (
    // <CommonLayout
    //   index='06'
    //   backtHref='/add-listing-5'
    //   nextHref='/add-listing-7'
    // >
    <FAQ />
    // </CommonLayout>
  );
};

export default PageAddListing6;
