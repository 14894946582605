/**
 * HONEY ESIM Detail Page Container
 */

import { ReactNode } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { imageGallery as listingCarImageGallery } from './listing-car-detail/constant';
import { imageGallery as listingExperienceImageGallery } from './listing-experiences-detail/constant';
import { imageGallery as listingStayImageGallery } from './listing-stay-detail/constant';

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get('modal');

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete('modal');
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    if (thisPathname?.includes('/listing-stay-detail')) {
      return listingStayImageGallery;
    }
    if (thisPathname?.includes('/listing-car-detail')) {
      return listingCarImageGallery;
    }
    if (thisPathname?.includes('/listing-experiences-detail')) {
      return listingExperienceImageGallery;
    }

    return [];
  };

  return (
    <div className='ListingDetailPage'>
      {/* <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      /> */}

      <div className='container ListingDetailPage__content'>{children}</div>

      {/* SUGGEST OTHER PRODUCTS */}
      {/* <div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div>
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div> */}
    </div>
  );
};

export default DetailPagetLayout;
