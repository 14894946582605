/**
 * HONEY ESIM - 지원기기
 */

import EID from 'images/user-guide/EID.png';
import EID_android from 'images/user-guide/EID_android.jpg';
import dialPad from 'images/user-guide/dial_pad.png';
import dialPad_android from 'images/user-guide/dial_pad_android.jpg';
import { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import CommonLayout from './CommonLayout';

export interface SupportedDevicesProps {}

const SupportedDevices: FC<SupportedDevicesProps> = () => {
  return (
    <CommonLayout>
      <div className='p-4 md:p-10'>
        <h2 className='text-4xl font-semibold mb-8'>사용 가능 기기</h2>
        <div className='grid grid-cols-1 gap-6 text-neutral-700 dark:text-neutral-300'>
          <div className='flex items-center space-x-3'>
            {/* <i className='las la-check-circle text-2xl text-primary-600'></i> */}
            <div className='sm:text-base'>
              전화거는 화면에서{' '}
              <span className='font-semibold text-lg'>
                *#06# (별표-샾-0-6-샾)
              </span>{' '}
              입력시 <span className='font-semibold text-lg'>EID</span> 정보가
              나오면 eSIM 사용이 가능합니다
            </div>
          </div>
          <h2 className='text-xl font-light'>iOS</h2>
          <div className='sm:flex'>
            <NcImage
              containerClassName='flex-1 items-center justify-center sm:w-full'
              className='mt-2'
              src={dialPad}
            />
            <div className='p-1'></div>

            <NcImage
              containerClassName='flex-1 items-center justify-center'
              className='mt-2'
              src={EID}
            />
          </div>
          <h2 className='text-xl font-light'>Android</h2>
          <div className='sm:flex'>
            <NcImage
              containerClassName='flex-1 items-center justify-center'
              className='mt-2'
              src={dialPad_android}
            />
            <div className='p-1'></div>
            <NcImage
              containerClassName='flex-1 items-center justify-center'
              className='mt-2'
              src={EID_android}
            />
          </div>
          <div className='my-10'></div>
          <div className='space-y-6 sm:space-y-8'>
            {/* HEADING */}
            <div className='max-w-2xl'>
              <h2 className='text-2xl font-semibold'>iOS 기기*</h2>

              <div className='overflow-hidden rounded-md bg-white '>
                <ul role='list' className=''>
                  {[
                    'iPhone: XR, XS 시리즈 이상',
                    'iPad: 7, Mini 5, Air 3, Pro 1 시리즈 이상',
                  ].map((item) => (
                    <li key={item} className='px-6 py-4'>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className='my-10'></div>

              <h2 className='text-2xl font-semibold'>Android 기기*</h2>

              <div className='overflow-hidden rounded-md bg-white mb-5'>
                <ul role='list' className=''>
                  {[
                    'Galaxy: S20, Z fold 1, Z flip 1 시리즈 이상 (글로벌)',
                    'Galaxy: S23, Z fold 4, Z flip 4 시리즈 이상 (한국)',
                    'Pixel: 3 시리즈 이상',
                    'Motorola, OnePlus, Huawei, Xiaomi, Oppo, Sony 등 최신 기기 eSIM 기능 지원',
                  ].map((item) => (
                    <li key={item} className='px-6 py-4'>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <div className='font-light text-red-500'>
                *같은 모델이라도 출시된 국가에 따라서 eSIM이 지원 안되는 기기가
                있습니다. 꼭 확인하시고 구매하시기 바랍니다.
              </div>
              <div className='my-10'></div>

              {/* <span className='text-xl font-semibold block'>
                Payout methods
              </span>
              <br />
              <span className='text-neutral-700 dark:text-neutral-300 block'>
                When you receive a payment for a reservation, we call that
                payment to you a "payout." Our secure payment system supports
                several payout methods, which can be set up below. Go to FAQ.
                <br />
                <br />
                To get paid, you need to set up a payout method Airbnb releases
                payouts about 24 hours after a guest’s scheduled check-in time.
                The time it takes for the funds to appear in your account
                depends on your payout method. Learn more
              </span>
              <div className='pt-10'>
                <ButtonPrimary>Add payout mothod</ButtonPrimary>
              </div> */}
            </div>
          </div>
        </div>
        <div className='my-10'></div>
      </div>
    </CommonLayout>
  );
};

export default SupportedDevices;
