/**
 * HONEY ESIM 메인 페이지- ESIM 상품 카드
 */

import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionBecomeAnAuthor from 'components/SectionBecomeAnAuthor/SectionBecomeAnAuthor';
import SectionGridCategoryBox from 'components/SectionGridCategoryBox/SectionGridCategoryBox';
import SectionHero from 'components/SectionHero/SectionHero';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import PageAbout from 'containers/PageAbout/PageAbout';
import SectionStatistic from 'containers/PageAbout/SectionStatistic';
import { TaxonomyType } from 'data/types';
import Review from 'images/review.webp';

const DEMO_CATS: TaxonomyType[] = [
  {
    id: '1',
    href: '/listing-stay',
    name: 'New Yourk',
    taxonomy: 'category',
    count: 188288,
    desc: 'eee',
    thumbnail:
      'https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  },
  {
    id: '2',
    href: '/listing-stay',
    name: 'Singapore',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '2',
    href: '/listing-stay',
    name: 'Paris',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '2',
    href: '/listing-stay',
    name: 'London',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  },
  {
    id: '2',
    href: '/listing-stay',
    name: 'Tokyo',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  },
  {
    id: '2',
    href: '/listing-stay',
    name: 'Maldives',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: '1',
    href: '/listing-stay',
    name: 'Enjoy the great cold',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  },
  {
    id: '222',
    href: '/listing-stay',
    name: 'Sleep in a floating way',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '3',
    href: '/listing-stay',
    name: "In the billionaire's house",
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '4',
    href: '/listing-stay',
    name: 'Cool in the deep forest',
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
  {
    id: '5',
    href: '/listing-stay',
    name: "In the billionaire's house",
    taxonomy: 'category',
    count: 188288,
    thumbnail:
      'https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  },
];

function PageHome() {
  return (
    <div className='nc-PageHome relative overflow-hidden'>
      {/* 배경 번짐 */}
      <BgGlassmorphism />

      <div className='container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28'>
        {/* Hero - 메인 슬로건, 국가 검색 */}
        <SectionHero className='pt-10 lg:pt-16 lg:pb-16' />
        <div className='md:pt-10'></div>

        {/* 나라별 선택 */}
        <div className='relative py-12'>
          <BackgroundSection />
          <SectionGridCategoryBox />
        </div>

        {/* 사용방법 3가지 스텝 */}
        <SectionHowItWork />

        {/* 대표 후기 */}
        <div className='flex justify-center'>
          <img src={Review} alt='Review Image' width='650px' />
        </div>

        {/* 꿀 이심 3사 가격비교 */}
        <div className='relative py-12'>
          <BackgroundSection className='bg-primary-50 dark:bg-black dark:bg-opacity-20 ' />
          <SectionStatistic />
        </div>

        {/* 꿀 이심 환불 */}
        <div className='relative py-12 sm:inline-block'>
          <SectionBecomeAnAuthor />
        </div>

        {/* 서비스 간략 소개 */}
        {/* <div className='hidden sm:inline-block'>
          <SectionOurFeatures />
        </div> */}
        {/* SECTION eSIM 가이드 */}
        <div className='relative py-16'>
          <BackgroundSection className='bg-primary-50 dark:bg-black dark:bg-opacity-20 ' />
          <PageAbout />
          {/* <SectionSubscribe2 /> */}
        </div>

        {/* <SectionSliderNewCategories uniqueClassName=""/> */}
      </div>
    </div>
  );
}

export default PageHome;
