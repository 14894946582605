/**
 * HONEY ESIM 결제 완료 페이지
 */

import { FC, useEffect } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

import { db, doc, getDoc } from 'api/firebase';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { currencyAtom } from 'store/atoms/currency';
import { productAtom, selectedPlanAtom } from 'store/atoms/product';
import { Plan, Product } from 'type/interface';

export interface PayPageProps {
  className?: string;
}

const EtransferPayDone: FC<PayPageProps> = ({ className = '' }) => {
  const { productName, planId } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const oid = searchParams.get('oid');
  const email = searchParams.get('email');
  const paid_amount = searchParams.get('paid_amount');

  const [productState, setProductState] = useAtom(productAtom);
  const [selectedPlanState, setSelectedPlanState] = useAtom(selectedPlanAtom);
  const currencyState = useAtomValue(currencyAtom);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'PRODUCTS', productName as string);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          // Update the product state without causing a re-render
          setProductState((prevProductState) => {
            const newData = docSnapshot.data() as Product;
            if (prevProductState !== newData) {
              sessionStorage.setItem('productState', JSON.stringify(newData));
              return newData;
            } else {
              return prevProductState;
            }
          });
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (productState.country.length === 0) {
      const storedProductState = sessionStorage.getItem('productState');
      // if product state exists in session storage,
      if (storedProductState) {
        const parsedStoredProductState = JSON.parse(storedProductState);
        // and product name matches the url param,
        if (parsedStoredProductState.product_name === productName) {
          // set product state
          setProductState(parsedStoredProductState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('productState');
          navigate('/404');
        }
      } else {
        // If product state is not found in session storage, fetch it from Firestore
        fetchData();
      }
    }
  }, [productName, navigate, productState, setProductState]);

  useEffect(() => {
    // if product state exists but not selectedPlanstate
    if (productState.country.length && !selectedPlanState.id) {
      // first check session storage
      const storedSelectedPlanState =
        sessionStorage.getItem('selectedPlanState');
      // if storedSelectedPlanState exists
      if (storedSelectedPlanState) {
        const parsedStoredSelectedPlanState = JSON.parse(
          storedSelectedPlanState
        );
        // check if id matches
        if (parsedStoredSelectedPlanState.id === planId) {
          // then set selected plan state
          setSelectedPlanState(parsedStoredSelectedPlanState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('selectedPlanState');
          navigate('/404');
        }
      } else {
        // if not in session storage, find it from product state
        const plan = productState.plans.find((d) => d.id === planId);
        if (!plan) {
          navigate('/404');
        }
        setSelectedPlanState(plan as Plan);
        sessionStorage.setItem('selectedPlanState', JSON.stringify(plan));
      }
    }
  }, [setSelectedPlanState, selectedPlanState, productState, navigate, planId]);

  const renderContent = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <h2 className='text-3xl lg:text-4xl font-semibold mt-8'>
          결제해주셔서 감사합니다
          <div className='text-base text-neutral-500 font-light mt-4'>
            입금 확인 후 eSIM 정보가 이메일로 발송됩니다
            <div>
              영업일 다음 날까지 이메일을 못 받으셨다면{' '}
              <a href='mailto:hello@honey-esim.com'>
                <span className='text-primary-500'> hello@honey-esim.com</span>
              </a>{' '}
              으로 문의주시면 감사하겠습니다
            </div>
          </div>
        </h2>

        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>

        {/* ------------------------ */}
        <div className='space-y-6'>
          <h3 className='text-2xl font-semibold'>주문하신 상품</h3>
          <div className='flex flex-col sm:flex-row sm:items-center border rounded-2xl border-primary-400'>
            {/* <div className='flex-shrink-0 w-full sm:w-40'>
              <div className=' aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden'>
                <NcImage src='https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940' />
              </div>
            </div> */}
            <div className='pt-5 pb-5 px-5 space-y-3'>
              <div>
                <span className='text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1'>
                  {selectedPlanState.categoryId === 'dp' ? '무제한' : ''}
                </span>
                <span className='text-base sm:text-lg font-medium mt-1 block'>
                  {selectedPlanState.name_kr}
                </span>
              </div>
              {/* <span className='block  text-sm text-neutral-500 dark:text-neutral-400'>
                2 beds · 2 baths
              </span> */}
              {/* <StartRating /> */}
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        {/* <div className='space-y-6 pt-4'>
          <h3 className='text-2xl font-semibold'>주문 상세 정보</h3>
          <div className='flex flex-col space-y-4'>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>오더 ID</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {oid}
              </span>
            </div>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>이메일</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {email}
              </span>
            </div>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>결제 금액</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {paid_amount}
              </span>
            </div>
          </div>
        </div> */}
        <div className='border-b border-neutral-200  dark:border-neutral-700'></div>

        <div className='flex justify-center pt-4'>
          <ButtonPrimary href='/products/korea-roaming1'>
            구매 가능한 eSIM 더 보기
          </ButtonPrimary>
          <div className='mx-1'></div>
          <ButtonSecondary href='/'>홈으로 가기</ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id='PayPage'>
      <main className='container mt-11 mb-24 lg:mb-32 '>
        <div className='max-w-4xl mx-auto'>{renderContent()}</div>
      </main>
    </div>
  );
};

export default EtransferPayDone;
