/**
 * HONEY ESIM 라우터
 */
import FooterNav from 'components/FooterNav';
import RequestEsim from 'containers/ActivationPage/RequestEsim';
import CheckOutPage from 'containers/CheckOutPage/CheckOutPage';
import PageEtransfer from 'containers/CheckOutPage/EtransferPage';
import EsimPlanDetail from 'containers/ListingDetailPage/listing-stay-detail/EsimPlanDetail';
import EsimPlanListing from 'containers/ListingFlightsPage/EsimPlanListing';
import Page404 from 'containers/Page404/Page404';
import PageAbout from 'containers/PageAbout/PageAbout';
import PageAddListing2 from 'containers/PageAddListing1/PageAddListing2';
import PageAddListing6 from 'containers/PageAddListing1/PageAddListing6';
import SupportedDevices from 'containers/PageAddListing1/SupportedDevices';
import WhatIsEsim from 'containers/PageAddListing1/WhatIsEsim';
import PageContact from 'containers/PageContact/PageContact';
import PageHome from 'containers/PageHome/PageHome';
import EtransferPayDone from 'containers/PayPage/EtransferPayDone';
import PayPage from 'containers/PayPage/PayPage';
import ProductPage from 'containers/ProductPage/ProductPage';

import SiteHeader from 'containers/SiteHeader';
import useWindowSize from 'hooks/useWindowResize';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from 'shared/Footer/Footer';
import ScrollToTop from './ScrollToTop';

export const pages: any = [
  { path: '/', exact: true, component: PageHome },
  { path: '/#', exact: true, component: PageHome },
  //
  { path: '/checkout/:productName/:planId', component: CheckOutPage },
  { path: '/pay-done/:productName/:planId', component: PayPage },
  {
    path: '/pay-done-etransfer/:productName/:planId',
    component: EtransferPayDone,
  },
  {
    path: '/request-qrcode/:orderID',
    component: RequestEsim,
  },

  //
  { path: '/contact', component: PageContact },

  { path: '/etransfer/:productName/:planId', component: PageEtransfer },

  //
];

const GuideRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PageAbout />} />
      {/* 설치방법 */}
      <Route path='/setup/*' element={<SetupRoutes />} />
      {/* 사용 가능 기기 */}
      <Route path='/supported-devices' element={<SupportedDevices />} />
      {/* FAQ */}
      <Route path='/faq' element={<PageAddListing6 />} />
      {/* WHAT IS ESIM */}
      <Route path='/what-is-esim' element={<WhatIsEsim />} />
    </Routes>
  );
};

const SetupRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PageAddListing2 />} />
      {/* Add other installation sub-paths here */}
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};

const ProductsRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<ProductPage />} />
      <Route path=':productName' element={<EsimPlanListing />} />
      <Route path=':productName/:planId' element={<EsimPlanDetail />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== 'undefined') {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className='min-h-screen flex flex-col'>
        {' '}
        {/* Use flex to make the container fill the screen height */}
        <SiteHeader />
        <div className='flex-grow mb-3'>
          <Routes>
            {' '}
            {/* Use flex-grow to make the content area grow to fill the remaining space */}
            {pages.map(({ component, path }: any) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route path='/user-guide/*' element={<GuideRoutes />} />
            <Route path='/products/*' element={<ProductsRoutes />} />
            <Route path='/404' element={<Page404 />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
        </div>
        {WIN_WIDTH < 768 && <FooterNav />}
        <div className='mt-auto'>
          <Footer /> {/* Use mt-auto to push the footer to the bottom */}
        </div>
      </div>
    </BrowserRouter>
  );
};

export default MyRoutes;
