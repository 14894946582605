import { collection, db, getDocs, query, where } from 'api/firebase';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import ProductsSearchResult from 'components/SectionGridCategoryBox/ProductsSearchResult';
import SectionGridCategoryBox from 'components/SectionGridCategoryBox/SectionGridCategoryBox';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Product {
  id: string;
  country: string[];
  country_en: string;
  country_kr: string;
  product_name: string;
  carrier: string;
  speed: string;
  isLocalEsim: boolean;
  canCallOrText: boolean;
  canActivateBeforeDeparture: boolean;
  eSimProvider: string;
  qrValidFor: number;
  plans: any[];
}
const Products = () => {
  const [searchParams] = useSearchParams();
  const countryName = searchParams.get('country');
  const [products, setProducts] = useState<Product[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // query countryName against DB
    // console.log('countryName', countryName);

    if (countryName) {
      const fetchProducts = async () => {
        const productsCollection = collection(db, 'PRODUCTS');
        const q = query(
          productsCollection,
          where('country', 'array-contains', countryName),
        );
        const querySnapshot = await getDocs(q);
        const fetchedProducts: any[] = querySnapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(fetchedProducts);
        console.log('searched products', fetchedProducts.length);
        // if there's only one result, navigate to ${doc.id}-roaming1 // "korea-roaming1"
        // if (fetchedProducts.length == 1) {
        navigate(`${fetchedProducts[0].id}`);
        // }
        // if there are more than one results, display them in cards
      };
      fetchProducts();
    }
  }, [countryName]);

  // 쿼리 파람이 없는 경우 (/products) => 전체 국가 선택
  // products?country=usa 같이 쿼리 파람이 있는 경우 관련 컨트리 페이지로 navigate (현재는 그냥 첫번째 프로덕트로 navigate 됨 navigate(`${fetchedProducts[0].id}`);)
  return (
    <>
      <section className='container relative z-20 overflow-hidden bg-white pt-5 sm:pb-12 sm:pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px] mb-2 h-max'>
        {!countryName ? (
          <div className='my-10'>
            <SectionGridCategoryBox />
          </div>
        ) : (
          products.length > 1 && (
            <>
              <div className='relative py-16'>
                <BackgroundSection />
                <ProductsSearchResult products={products} />
              </div>

              <div className='my-20'></div>

              <SectionGridCategoryBox />
            </>
          )
        )}
      </section>
    </>
  );
};

export default Products;
