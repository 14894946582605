import Heading from 'components/Heading/Heading';
import React from 'react';
import NcImage from 'shared/NcImage/NcImage';
import esim_FAQ from 'images/esim_FAQ.png';
import esim_definition from 'images/esim_definition.png';
import esim_devices from 'images/esim_devices.png';
import esim_installation from 'images/esim_installation.png';
import { useNavigate } from 'react-router-dom';
import CardCategoryBox1 from 'components/CardCategoryBox1/CardCategoryBox1';

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
  href: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: '1',
    name: `설치 방법`,
    href: '/user-guide/setup',
    job: '처음 eSIM을 사용해 보신다면',
    avatar: esim_installation,
  },
  {
    id: '4',
    name: `사용 가능 기기`,
    href: '/user-guide/supported-devices',

    job: '내 기기로 eSIM을 사용할 수 있나요?',
    avatar: esim_devices,
  },
  {
    id: '3',
    name: `FAQ`,
    href: '/user-guide/faq',

    job: '자주 묻는 질문들을 모아봤어요',
    avatar: esim_FAQ,
  },
  {
    id: '2',
    name: `eSIM이 뭔가요?`,
    href: '/user-guide/what-is-esim',

    job: 'eSIM은 뭐고 유심과 차이가 뭐죠?',
    avatar: esim_definition,
  },
];

const SectionFounder = () => {
  const navigate = useNavigate();

  return (
    <div className='nc-SectionFounder relative'>
      <Heading
        isCenter={true}
        desc='eSIM을 어떻게 설치하고 사용하는지 자세한 사용 설명서가 준비되어
        있습니다'
      >
        📙 꿀eSIM 사용설명서
      </Heading>
      <div className='grid md:grid-cols-2 gap-x-3 gap-y-8 xl:grid-cols-4 xl:gap-x-8 cursor-pointer justify-center md:justify-start'>
        {FOUNDER_DEMO.map((item, i) => (
          <div key={i}>
            <div
              onClick={() => navigate(item.href)}
              className='hidden md:block max-w-sm border-netural-200 border-2 p-5 rounded-xl hover:border-primary-400'
            >
              <NcImage
                containerClassName='relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden'
                className='absolute inset-0 object-cover'
                src={item.avatar}
              />
              <h3 className='text-center text-xl font-semibold text-neutral-900 mt-4 md:text-2xl dark:text-neutral-200'>
                {item.name}
              </h3>
              <span className='mt-3 text-center block text-sm text-neutral-500 sm:text-base dark:text-neutral-400'>
                {item.job}
              </span>
            </div>
            <div className='md:hidden'>
              <CardCategoryBox1
                taxonomy={{
                  href: item.href as string,
                  thumbnail: item.avatar,
                  name: item.name,
                  eng: '',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
