'use client';
import { useNavigate } from 'react-router-dom';
import ButtonSubmit from './ButtonSubmit';

import { MapPinIcon } from '@heroicons/react/24/outline';
import { FC, useEffect, useRef, useState } from 'react';
import ClearDataButton from './ClearDataButton';

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = '여행 국가 검색하기',
  desc = '어디로 여행가시나요?',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
}) => {
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedCountry, setSelectedCountry] = useState<any>(null); // selected country obj
  const [inputValue, setInputValue] = useState(''); // input value "ko"
  const [searchableItems, setSearchableItems] = useState<any[]>([]); // searchable countries
  const [focusedIndex, setFocusedIndex] = useState<number>(-1); // focused index for keyboard navigation

  const [showPopover, setShowPopover] = useState(autoFocus);

  const COUNTRIES = [
    {
      name: 'korea',
      displayNameKr: '한국',
      displayNameEn: 'Korea',
      spelledOut: ['ㅎ', '하', '한ㄱ', '한구'],
    },
    {
      name: 'japan',
      displayNameKr: '일본',
      displayNameEn: 'Japan',
      spelledOut: ['ㅇ', '이', '일ㅂ', '읿', '일보'],
    },
    {
      name: 'usa',
      displayNameKr: '미국',
      displayNameEn: 'USA',
      spelledOut: ['ㅁ', '믹', '미구'],
    },
    {
      name: 'vietnam',
      displayNameKr: '베트남',
      displayNameEn: 'Vietnam',
      spelledOut: ['ㅂ', '베', '벹', '베트', '베튼', '베트나'],
    },
  ];

  const POPULAR_COUNTRIES = [
    { name: 'korea', displayNameKr: '한국', displayNameEn: 'Korea' },
    { name: 'japan', displayNameKr: '일본', displayNameEn: 'Japan' },
    { name: 'usa', displayNameKr: '미국', displayNameEn: 'USA' },
    { name: 'vietnam', displayNameKr: '베트남', displayNameEn: 'Vietnam' },
  ];

  useEffect(() => {
    setSearchableItems(COUNTRIES);
  }, []);

  useEffect(() => {
    const filtered = COUNTRIES.filter((s: any) => {
      const lowercase = (s.displayNameKr + ' ' + s.displayNameEn).toLowerCase();

      return (
        lowercase.includes(inputValue.toLowerCase().trim()) ||
        s.spelledOut.includes(inputValue)
      );
    });
    setSearchableItems(filtered);
    setFocusedIndex(-1); // Reset focused index when input value changes
  }, [inputValue, setSearchableItems]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && searchableItems.length === 1) {
        handleSelectCountry(searchableItems[0]);
      } else if (event.key === 'ArrowDown') {
        setFocusedIndex((prevIndex) =>
          Math.min(prevIndex + 1, searchableItems.length - 1),
        );
      } else if (event.key === 'ArrowUp') {
        setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'Enter' && focusedIndex !== -1) {
        handleSelectCountry(searchableItems[focusedIndex]);
      }
    };

    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [searchableItems, focusedIndex]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  // 유저가 클릭 혹은 나라 검색/선택 후 엔터를 눌렀을 때 navigate 작동
  const handleSelectCountry = (country: any) => {
    setSelectedCountry(country); // korea obj
    setInputValue(`${country.displayNameKr} ${country.displayNameEn}`);
    setShowPopover(false);
    navigate(`/products/?country=${country.name}`); // navigate to ProductPage.tsx
  };

  // 검색창 클릭시 나옴
  const renderRecentSearches = () => {
    return (
      <>
        <h3 className='block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100'>
          인기 여행지
        </h3>
        <div className='mt-2'>
          {POPULAR_COUNTRIES.map((item: any, index: number) => (
            <span
              onClick={() => handleSelectCountry(item)}
              key={item.name}
              className={`flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer ${
                focusedIndex === index
                  ? 'bg-neutral-100 dark:bg-neutral-700'
                  : ''
              }`}
            >
              <span className='block text-neutral-400'>
                <MapPinIcon className='h-4 sm:h-6 w-4 sm:w-6' />
              </span>
              <span className=' block font-medium text-neutral-700 dark:text-neutral-200'>
                {item.displayNameKr}
              </span>
              <span className='text-sm text-neutral-500 dark:text-neutral-200'>
                {' '}
                {item.displayNameEn}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  // 검섹 결과 필터 창 (입력시 바로 반영)
  const renderSearchValue = () => {
    return (
      <>
        <h3 className='block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100'>
          검색 결과
        </h3>
        <div className='mt-2'></div>
        {searchableItems.length ? (
          searchableItems.map((item: any, index: number) => (
            <span
              onClick={() => handleSelectCountry(item)}
              key={item.name}
              className={`flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer ${
                focusedIndex === index
                  ? 'bg-neutral-100 dark:bg-neutral-700'
                  : ''
              }`}
            >
              <span className='block text-neutral-400'>
                <MapPinIcon className='h-4 w-4 sm:h-6 sm:w-6' />
              </span>
              <span className=' block font-medium text-neutral-700 dark:text-neutral-200'>
                {item.displayNameKr}
              </span>
              <span className='text-sm text-neutral-500 dark:text-neutral-200'>
                {item.displayNameEn}
              </span>
            </span>
          ))
        ) : (
          <span className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'>
            <span className=' block font-medium text-neutral-700 dark:text-neutral-200'>
              해당 여행지가 없습니다
            </span>
          </span>
        )}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? 'nc-hero-field-focused' : ''
        }`}
      >
        <div className='text-neutral-300 dark:text-neutral-400'>
          <MapPinIcon className='w-5 h-5 lg:w-7 lg:h-7' />
        </div>
        <div className='flex-grow'>
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={inputValue}
            autoFocus={showPopover}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className='block mt-0.5 text-sm text-neutral-400 font-light '>
            <span className='line-clamp-1'>
              {!!inputValue ? placeHolder : desc}
            </span>
          </span>
          {inputValue && showPopover && (
            <ClearDataButton
              onClick={() => {
                setInputValue('');
              }}
            />
          )}
        </div>
        <div className='pr-2 xl:pr-4'>
          <ButtonSubmit href='#' />
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className='absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto'>
          {inputValue ? renderSearchValue() : renderRecentSearches()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
