import { useAtomValue, useSetAtom } from 'jotai';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';
import { currencyAtom } from 'store/atoms/currency';
import { productAtom, selectedPlanAtom } from 'store/atoms/product';
import { Plan } from 'type/interface';
import { currencyConversion } from 'utils/currencyConversion';

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: Plan;
}

const FlightCard: FC<FlightCardProps> = ({
  className = '',
  data,
  defaultOpen = false,
}) => {
  const currencyState = useAtomValue(currencyAtom);
  const productState = useAtomValue(productAtom);
  const setSelectedPlan = useSetAtom(selectedPlanAtom);

  const navigate = useNavigate();

  const handleClick = () => {
    setSelectedPlan(data);
    sessionStorage.setItem('selectedPlanState', JSON.stringify(data));
    navigate(`/products/${productState.product_name}/${data.id}`);
  };

  const generatPlanDesc = (data: any) => {
    const isDailyPlan = data.categoryId === 'dp';
    const isUnlimited = !!data.unlimiitedCapacity;

    if (isDailyPlan && isUnlimited) {
      return `매일 ${data.capacityId} 사용 후 ${data.unlimiitedCapacity} 속도로 무제한`;
    } else if (!isDailyPlan && isUnlimited) {
      return `${data.days}일 동안 총 ${data.capacityId}의 데이터 사용 후 ${data.unlimiitedCapacity} 속도로 무제한`;
    } else if (isDailyPlan && !isUnlimited) {
      return `매일 ${data.capacityId}의 데이터만 사용 가능`;
    } else {
      return `${data.days}일 동안 총 ${data.capacityId}의 데이터만 사용 가능`;
    }
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 hover:border hover:border-primary-400 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className} cursor-pointer`}
      data-nc-id='FlightCard'
      onClick={handleClick} // Add onClick handler
    >
      <div className={`sm:pr-20 relative ${className}`} data-nc-id='FlightCard'>
        <div className='flex flex-row sm:items-center space-y-6 sm:space-y-0'>
          {/* 기간 */}
          <div id='period' className='lg:block flex-1'>
            <div className='font-semibold text-xl flex items-center'>
              {data.days}
              <span className='text-lg text-neutral-400'>일</span>
              {data.unlimiitedCapacity.includes('mbps') && (
                <Badge name='초고속 무제한 데이터' className='ml-2' />
              )}
            </div>
            <div className='text-sm text-neutral-500 font-normal mt-0.5'>
              {generatPlanDesc(data)}
            </div>
          </div>

          {/* PRICE (Moved to the right side on small screens) */}
          <div
            id='price-tag'
            className='pl-2 sm:text-right flex-none w-22 flex justify-end items-center'
          >
            <div className='text-xs sm:text-sm text-neutral-500 font-normal mt-0.3 mr-1'>
              {currencyState}
            </div>
            <div>
              <span className='text-xl font-semibold text-secondary-6000'>
                {currencyConversion(data.price as number, currencyState)}
                <span>{currencyState === 'KRW' && '원'}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightCard;
