import React, { FC } from 'react';
import { TaxonomyType } from 'data/types';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import Badge from 'shared/Badge/Badge';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface CardCategoryBox1Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = '',
  taxonomy,
}) => {
  const { active, name, thumbnail, note, eng, href = '/' } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategoryBox1 relative flex items-center px-10 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${
        active ? '' : `cursor-not-allowed`
      } ${className}`}
      data-nc-id='CardCategoryBox1'
    >
      {note && (
        <Badge className='absolute right-2 top-2' color='gray' name={note} />
      )}

      <div className='relative flex-shrink-0 w-20 h-20 rounded-full overflow-hidden'>
        <NcImage src={thumbnail} containerClassName='absolute inset-0' />
      </div>
      <div className='ml-4 flex-grow overflow-hidden'>
        <h2 className='text-base sm:text-2xl font-medium'>
          <span className='line-clamp-1'>{name}</span>
        </h2>
        <span
          className={`block mt-2 text-base text-neutral-500 dark:text-neutral-400`}
        >
          {eng}
        </span>
      </div>
    </Link>
  );
};

export default CardCategoryBox1;
