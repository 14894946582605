import { FC, Fragment, useEffect, useState } from 'react';

import { Tab } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';
import AAgalaxy from './AAgalaxy';
import AAiphone from './AAiphone';
import BDgalaxy from './BDgalaxy';
import BDiphone from './BDiphone';
import Mgalaxy from './Mgalaxy';
import Miphone from './Miphone';

export interface PageAddListing2Props {}
interface TabType {
  id: number;
  name: string;
}
const CATEGORY_TABS: TabType[] = [
  { id: 1, name: '아이폰' },
  { id: 2, name: '갤럭시' },
];

const categories = [
  { id: 1, name: '출국 전' },
  { id: 2, name: '현지 도착 후' },
  { id: 3, name: '수동 설정' },

  // { name: "3. 최종 확인", href: "/user-guide/setup/aa-iphone-1" },
];

const notes = [
  { name: '네트워크가 연결된 장소에서만 eSIM 설치가 가능합니다' },
  { name: 'eSIM 설치 후 기기변경이 불가합니다' },
  {
    name: '설치 즉시 일수가 차감되는 제품도 있으니, 제품 상세 페이지에서 꼭 확인하세요',
  },
];
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const SetupContent_BeforeDeparture = (selectedTabId: number) => {
  return selectedTabId === 1 ? <BDiphone /> : <BDgalaxy />;
};

const SetupContent_AfterArrival = (selectedTabId: number) => {
  return selectedTabId === 1 ? <AAiphone /> : <AAgalaxy />;
};

const SetupContent_Manual = (selectedTabId: number) => {
  return selectedTabId === 1 ? <Miphone /> : <Mgalaxy />;
};

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const [searchParams] = useSearchParams();

  const device = Number(searchParams.get('device'));
  const time = Number(searchParams.get('time'));

  const [selectedTabId, setSelectedTabId] = useState(1);
  const [selectedSubTabId, setSelectedSubTabId] = useState(1);
  const [showWarning, setShowWarning] = useState(true);

  // device and time = queryParams
  useEffect(() => {
    setSelectedTabId(device || 1);
    setSelectedSubTabId(time || 1);
  }, [device, time]);

  const renderEsimSetupGuide = () => {
    return (
      <div className='space-y-6 sm:space-y-8 mt-0'>
        <div>
          <Tab.Group>
            {/* 출국 전/ 현지 도착 후 TAB */}
            <Tab.List className='flex space-x-1 overflow-x-auto'>
              {categories.map((item) => (
                <Tab key={item.name} as={Fragment}>
                  {() => (
                    <button
                      onClick={() => setSelectedSubTabId(item.id)}
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-base md:text-lg sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selectedSubTabId === item.id
                          ? 'bg-secondary-900 text-secondary-50 '
                          : 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 bg-neutral-50 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                      } `}
                    >
                      <span className='text-sm sm:text-base lg:text-lg font-semibold'>
                        {' '}
                        {item.name}
                      </span>
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>

            {/* 설치 방법 */}
            <Tab.Panels>
              {categories.map((item) => (
                <Tab.Panel key={item.id}>
                  <>
                    {selectedSubTabId === 1 &&
                      SetupContent_BeforeDeparture(selectedTabId)}
                    {selectedSubTabId === 2 &&
                      SetupContent_AfterArrival(selectedTabId)}
                    {selectedSubTabId === 3 &&
                      SetupContent_Manual(selectedTabId)}
                  </>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id='PageAddListing1'
    >
      <div className='space-y-11'>
        <div>
          <span className='text-4xl font-semibold'>eSIM 설치 방법</span>
        </div>

        {/* 설치하기 전에 NOTIF */}
        {showWarning && (
          <div className='rounded-md border-l-4 border-yellow-400 bg-yellow-50 p-4'>
            <div className='flex'>
              <div className='ml-3'>
                <div className='grid grid-cols-1 gap-6 text-neutral-700 dark:text-neutral-300'>
                  {notes
                    .filter((_, i) => i < 12)
                    .map((item) => (
                      <div
                        key={item.name}
                        className='flex items-center space-x-3'
                      >
                        <CheckCircleIcon
                          className='h-5 w-5 text-primary-400'
                          aria-hidden='true'
                        />
                        <span className='sm:text-base'>{item.name}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='ml-auto pl-3'>
                <div className='-mx-1.5 -my-1.5'>
                  <button
                    type='button'
                    className='inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50'
                  >
                    <span className='sr-only'>Dismiss</span>
                    <XMarkIcon
                      className='h-5 w-5'
                      aria-hidden='true'
                      onClick={() => setShowWarning(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 아이폰 / 갤럭시 설치 탭 */}
        <div>
          <div className='block'>
            <div className='border-b border-gray-200'>
              <nav className='-mb-px flex' aria-label='Tabs'>
                {CATEGORY_TABS.map((tab) => (
                  <div
                    key={tab.name}
                    // to={tab.href}
                    className={classNames(
                      tab.id === selectedTabId
                        ? 'border-primary-500 text-primary-600 flex-grow lg:flex-grow-0 cursor-pointer	'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium flex-grow lg:flex-grow-0 cursor-pointer	'
                    )}
                    onClick={() => setSelectedTabId(tab.id)}
                  >
                    <span className='text-xl font-semibold'>{tab.name}</span>
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* 출국 전/ 현지 도착 후 설치 */}
        {renderEsimSetupGuide()}
      </div>
    </div>
  );
};

export default PageAddListing2;
