import I_AA_1 from 'images/user-guide/setup/I_AA_1.png';
import I_AA_2 from 'images/user-guide/setup/I_AA_2.png';
import I_AA_3 from 'images/user-guide/setup/I_AA_3.png';
import I_AA_4 from 'images/user-guide/setup/I_AA_4.png';
import I_BD_1 from 'images/user-guide/setup/I_BD_1.png';
import I_BD_12 from 'images/user-guide/setup/I_BD_12.png';

import CommonLayout from './CommonLayout';

export default function AAiphone() {
  return (
    <CommonLayout
      index='01'
      backtHref=''
      nextHref='/user-guide/setup/aa-iphone-2'
    >
      <>
        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 1. '}
            <span className='text-bold text-black'>{'설정 > 셀룰러'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 2. '}
            <span className='text-bold text-black'>
              {'미리 설치한 여행용 eSIM 회션 "켜기"'}
            </span>
            <div className='text-sm text-slate-800'>
              여행용 eSIM의 회선을 활성화 시킵니다
            </div>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_BD_12} className='w-4/5 sm:w-2/3' />
          </div>
          <div className='my-1'></div>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_AA_4} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 3. '}
            <span className='text-bold text-black'>
              {'스크롤을 내려서 데이터로밍 켜기'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_AA_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 4. '}
            <span className='text-bold text-black'>
              {'설정 > 셀룰러 에서 셀룰러 데이터 클릭'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_AA_2} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 5. '}
            <span className='text-bold text-black'>
              {'"메인"에서 "여행용"으로 변경'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_AA_3} className='w-4/5 sm:w-2/3' />
          </div>
        </div>
      </>
    </CommonLayout>
  );
}
