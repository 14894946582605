/**
 * HONEY ESIM 커스터머 서비스
 */

import axios from 'axios';
import Label from 'components/Label/Label';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = '' }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isEmailSent, setIsEmailSent] = useState(false);

  const onSubmit = (data: any) => {
    const { name, email, text } = data;
    sendNoTemplateEmail(email, name, text);
  };

  const sendNoTemplateEmail = (email: string, name: string, text: string) => {
    interface EmailParams {
      email: string;
      params: any;
    }

    const emailParams: EmailParams = {
      email, // 파트너 ID
      params: {
        name,
        text,
      },
    };

    axios.post(
      'https://us-central1-esim-connect.cloudfunctions.net/sendNoTemplateEmail',
      emailParams,
    );
    setIsEmailSent(true);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className} h-screen`}
      data-nc-id='PageContact'
    >
      <Helmet>
        <title>꿀이심 고객센터</title>
      </Helmet>
      <div className='mb-24 lg:mb-32'>
        <h2 className='my-10 sm:my-14 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          고객센터
        </h2>

        <div className='container max-w-7xl mx-auto flex items-center justify-center'>
          <div className='flex-shrink-0 grid grid-cols-1 lg:px-20 md:w-80% md:px-20 w-full'>
            <div>
              {isEmailSent ? (
                <h2 className='text-3xl lg:text-4xl font-semibold mt-8 text-center'>
                  이메일이 발송되었습니다 🙌
                  <div className='text-base text-neutral-500 font-light mt-4'>
                    이메일 확인 후 답변드리도록 하겠습니다. 소중한 피드백
                    감사합니다 🙏🙏
                  </div>
                </h2>
              ) : (
                <form
                  className='grid grid-cols-1 gap-6'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <label className='block'>
                    <Label>성함</Label>

                    <Input
                      id='name'
                      placeholder='성함을 기입해 주세요'
                      type='text'
                      className='mt-1'
                      {...register('name', { required: true })}
                    />
                    {errors.name && (
                      <span className='text-red-500 mt-1 text-sm ml-2'>
                        성함을 입력해주세요
                      </span>
                    )}
                  </label>
                  <label className='block'>
                    <Label>이메일</Label>

                    <Input
                      id='email'
                      type='email'
                      placeholder='example@example.com'
                      className='mt-1'
                      {...register('email', {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    {errors.email && (
                      <span className='text-red-500 mt-1 text-sm ml-2'>
                        올바른 이메일 주소를 입력해주세요
                      </span>
                    )}
                  </label>
                  <label className='block'>
                    <Label>메시지</Label>

                    <Textarea
                      id='text'
                      className='mt-1'
                      placeholder='문의사항이나 피드백을 자세히 기입해 주세요. 영업일 기준 24시간 안으로 답변 드리겠습니다.'
                      rows={6}
                      {...register('text', { required: true })}
                    />
                    {errors.text && (
                      <span className='text-red-500 mt-1 text-sm ml-2'>
                        메시지를 입력해주세요
                      </span>
                    )}
                  </label>
                  <div>
                    <ButtonSecondary type='submit'>
                      메시지 보내기
                    </ButtonSecondary>
                  </div>
                  <div>
                    <ButtonPrimary href={'https://pf.kakao.com/_AkxnWG/chat'}>
                      카톡으로 문의하기
                    </ButtonPrimary>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
    </div>
  );
};

export default PageContact;
