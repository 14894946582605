/**
 * HONEY ESIM 404
 */

import I404Png from 'images/404.png';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';

const Page404: React.FC = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clean up the interval when component unmounts or when countdown reaches 0
    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className='nc-Page404'>
      <Helmet>
        <title>404 || Booking React Template</title>
      </Helmet>
      <div className='container relative pt-5 pb-16 lg:pb-20 lg:pt-5'>
        {/* HEADER */}
        <header className='text-center max-w-2xl mx-auto space-y-2'>
          <NcImage src={I404Png} />
          <span className='block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium'>
            요청하신 페이지가 존재하지 않습니다{' '}
          </span>
          <div>{countdown}초 후 홈으로 이동합니다</div>
          <div className='pt-8'>
            <ButtonPrimary href='/'>홈으로 돌아가기</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Page404;
