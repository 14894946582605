/**
 * HONEY ESIM Product Page - South Korea
 */

import { db, doc, getDoc } from 'api/firebase';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'shared/Loader/Loader';
import { productAtom } from 'store/atoms/product';
import { Product } from 'type/interface';
import SectionGridFilterCard from './SectionGridFilterCard';

export interface EsimPlanListingProps {
  className?: string;
}

const EsimPlanListing: FC<EsimPlanListingProps> = ({ className = '' }) => {
  // query "Korea" -> 2 proucts found -> when user clicks each product, setProductState
  const { productName } = useParams();

  const navigate = useNavigate();

  const [productState, setProductState] = useAtom(productAtom);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const docRef = doc(db, 'PRODUCTS', productName as string);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          // Update the product state without causing a re-render
          setProductState((prevProductState) => {
            const newData = docSnapshot.data() as Product;
            if (prevProductState !== newData) {
              sessionStorage.setItem('productState', JSON.stringify(newData));
              return newData;
            } else {
              return prevProductState;
            }
          });
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
      setIsLoading(false);
    };

    const sessionProductName = sessionStorage.getItem('productName');
    if (sessionProductName !== productName) {
      sessionStorage.setItem('productName', productName as string);
      fetchData();
    } else {
      if (productState.country.length === 0) {
        const storedProductState = sessionStorage.getItem('productState');
        // if product state exists in session storage,
        if (storedProductState) {
          const parsedStoredProductState = JSON.parse(storedProductState);
          // and product name matches the url param,
          if (parsedStoredProductState.product_name === productName) {
            // set product state
            setProductState(parsedStoredProductState);
          } else {
            // otherwise remove the session storage and redirect to 404
            sessionStorage.removeItem('productState');
            navigate('/404');
          }
        } else {
          // If product state is not found in session storage, fetch it from Firestore
          fetchData();
        }
      }
      setIsLoading(false);
    }
  }, [productName, navigate, productState, setProductState]);

  return (
    <div
      className={`nc-EsimPlanListing relative ${className}`}
      data-nc-id='EsimPlanListing'
    >
      <Helmet>
        <title>꿀이심 || eSIM 플랜 리스트</title>
      </Helmet>
      <BgGlassmorphism />

      {/* 상품 리스팅 */}

      <div className='container relative'>
        {isLoading ? (
          <Loader />
        ) : (
          <SectionGridFilterCard className='pb-24 lg:pb-28' />
        )}
      </div>
    </div>
  );
};

export default EsimPlanListing;
