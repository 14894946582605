import { FC, useState } from 'react';
import LocationInput from '../LocationInput';

interface Props {
  closeModal?: any;
}

const StaySearchForm: FC<Props> = ({ closeModal }) => {
  const [locationInputTo, setLocationInputTo] = useState('');

  const renderInputLocation = () => {
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 rounded-2xl shadow-lg`}
      >
        <LocationInput
          defaultValue={locationInputTo}
          onChange={(value) => {
            setLocationInputTo(value);
          }}
          closeModal={closeModal}
        />
      </div>
    );
  };

  return (
    <div>
      <div className='w-full space-y-5'>{renderInputLocation()}</div>
    </div>
  );
};

export default StaySearchForm;
