/**
 * Honey ESIM Button Component
 */

import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-300 hover:bg-primary-400 text-neutral-800 border border-slate-400 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
