/**
 * HONEY ESIM 가이드 페이지
 */

import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import SectionHoneyEsimGuideGrids from './SectionFounder';

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>꿀eSIM || 해외여행 필수 데이터는 저렴한 꿀eSIM으로</title>
      </Helmet>
      <BgGlassmorphism />
      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <SectionHoneyEsimGuideGrids />
      </div>
    </div>
  );
};

export default PageAbout;
