import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'shared/Logo/Logo';

interface MobileHeaderProps {}

const MobileBackButton: React.FC<MobileHeaderProps> = ({}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleBackButtonClick = () => {
    if (pathname.includes('pay-done')) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  const renderBackButton = () => {
    switch (true) {
      case pathname === '/' || pathname.includes('pay-done'):
        return <Logo />;

      default:
        return (
          <div className='' onClick={handleBackButtonClick}>
            <i className='las la-arrow-left text-3xl'></i>
          </div>
        );
    }
  };

  return (
    <div className='flex justify-start items-center'>{renderBackButton()}</div>
  );
};

export default MobileBackButton;
