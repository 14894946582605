/**
 * HONEY ESIM 헤더
 */

import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'shared/Logo/Logo';
// import CurrencyDropdown from './CurrencyDropdown';
import UserGuide from './DropdownTravelers';
// import LangDropdown from './LangDropdown';
import HeroSearchForm2Mobile from 'components/HeroSearchForm2Mobile/HeroSearchForm2Mobile';
import MobileBackButton from './MobileBackButton';
export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = '' }) => {
  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 py-2 ${className}`}>
      <div
        id='search'
        className='px-4 lg:container py-1 lg:py-2 relative md:flex items-center'
      >
        <div className='hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10'>
          <Logo />

          <div className='hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500'></div>
          <div className='hidden md:block'>
            <UserGuide />
          </div>
        </div>

        {/* <div className='md:hidden flex-[3] max-w-lg !mx-auto md:px-3'>
          <MobileBackButton />
          <HeroSearchForm2Mobile />
        </div> */}

        <div className='md:hidden flex max-w-lg mx-auto md:px-3 items-center space-x-2'>
          <div className='flex-shrink-0'>
            <MobileBackButton />
          </div>
          <div className='flex-grow'>
            <HeroSearchForm2Mobile />
          </div>
        </div>

        <div className='md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100'>
          <div className='items-center md:flex space-x-1'>
            {/* <CurrencyDropdown /> */}
            {/* <LangDropdown /> */}
            {/* 
            <div></div>
            
            <SwitchDarkMode />
            <div className="pr-1.5">
              <NotifyDropdown className="-ml-2 xl:-ml-1" />
            </div>
            <AvatarDropdown /> */}
            {/* <div className='pr-4'>
              <Link
                to='/products'
                className=' text-semibold text-base
                group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-primary-300'
              >
                고객 후기{' '}
              </Link>
            </div> */}

            <div>
              <Link to={'/contact'}>
                <EnvelopeIcon className='hidden md:inline-block -ml-2 xl:-ml-1 w-6 text-sm hover:text-primary-400 outline-slate-400' />
              </Link>
            </div>
          </div>
          <div className='flex items-center space-x-2 lg:hidden'>
            {/* <NotifyDropdown />
            <AvatarDropdown />
            <MenuBar /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
