import { Provider } from 'jotai';
import ReactDOM from 'react-dom/client';

//
import 'rc-slider/assets/index.css';
// STYLE
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
import './index.css';
import './styles/index.scss';

//
import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    paypleGpayPaymentRequest: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider>
    <App />
  </Provider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
