/**
 * HONEY ESIM 나라별 선택
 */

import CardCategoryBox2 from 'components/CardCategoryBox1/CardCategoryBox2';
import Heading from 'components/Heading/Heading';
import { TaxonomyType } from 'data/types';
import europe from 'images/countries/europe.jpg';
import japan from 'images/countries/japan.jpg';
import korea from 'images/countries/korea.jpg';
import mexico from 'images/countries/mexico.jpg';
import philippines from 'images/countries/philippines.jpg';
import thailand from 'images/countries/thailand.jpg';
import usa_canada from 'images/countries/usa_canada.jpg';
import vietnam from 'images/countries/vietnam.jpg';
import React from 'react';

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: 'card1';
  className?: string;
  gridClassName?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: '1',
    href: '/products/korea-roaming1',
    name: '한국',
    taxonomy: 'category',
    count: undefined,
    eng: 'Korea',
    note: '',
    active: true,
    thumbnail: korea,
  },
  {
    id: '2',
    href: '/products/japan-roaming1',
    name: '일본',
    taxonomy: 'category',
    count: undefined,
    eng: 'Japan',
    note: '',
    active: true,
    thumbnail: japan,
  },
  {
    id: '3',
    href: '/products/usa-roaming1',
    name: '미국',
    taxonomy: 'category',
    count: undefined,
    eng: 'USA',
    note: '',
    active: true,
    thumbnail: usa_canada,
  },
  {
    id: '4',
    href: '/products/vietnam-roaming1',
    name: '베트남',
    taxonomy: 'category',
    count: undefined,
    eng: 'Vietnam',
    note: '',
    active: true,
    thumbnail: vietnam,
  },
  {
    id: '1',
    href: '',
    name: '유럽',
    taxonomy: 'category',
    count: undefined,
    eng: 'Europe',
    note: '곧 발매 예정',
    active: false,
    thumbnail: europe,
  },

  {
    id: '1',
    href: '',
    name: '멕시코',
    taxonomy: 'category',
    count: undefined,
    eng: 'Mexico',
    note: '곧 발매 예정',
    active: false,
    thumbnail: mexico,
  },
  {
    id: '1',
    href: '',
    name: '필리핀',
    taxonomy: 'category',
    count: undefined,
    eng: 'Philippines',
    note: '곧 발매 예정',
    active: false,
    thumbnail: philippines,
  },
  {
    id: '1',
    href: '',
    name: '태국',
    taxonomy: 'category',
    count: undefined,
    eng: 'Thailand',
    note: '곧 발매 예정',
    active: false,
    thumbnail: thailand,
  },
];

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = 'card1',
  headingCenter = true,
  className = '',
  gridClassName = 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
}) => {
  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading desc='여행하시는 나라를 선택해주세요' isCenter={headingCenter}>
        인기 국가
      </Heading>
      <div className={`grid ${gridClassName} gap-2 sm:gap-6 md:gap-8`}>
        {categories.map((item, i) => (
          <CardCategoryBox2 key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
