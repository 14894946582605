import I_M_1 from 'images/user-guide/setup/I_M_1.png';
import I_M_2 from 'images/user-guide/setup/I_M_2.png';
import CommonLayout from './CommonLayout';

export default function AAiphone() {
  return (
    <CommonLayout
      index='01'
      backtHref=''
      nextHref='/user-guide/setup/aa-iphone-2'
    >
      <>
        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 1. '}
            <span className='text-bold text-black'>
              {'QR코드 스캔 화면에서 하단의 "세부사항 직접 입력"'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_M_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 2. '}
            <span className='text-bold text-black'>
              {'결제 후 받은 이메일에서 해당 정보 복사 후 입력'}
            </span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={I_M_2} className='w-4/5 sm:w-2/3' />
          </div>
        </div>
      </>
    </CommonLayout>
  );
}
