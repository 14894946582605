import { MagnifyingGlassIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  closeModal?: any;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = '',
  defaultValue = 'United States',
  headingText = '어디로 여행을 가시나요?',
  closeModal,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<any>(null); // selected country obj
  const [inputValue, setInputValue] = useState(''); // input value "ko"
  const [searchableItems, setSearchableItems] = useState<any[]>([]); // searchable countries
  const [showPopover, setShowPopover] = useState(false);
  const containerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const COUNTRIES = [
    {
      name: 'korea',
      displayNameKr: '한국',
      displayNameEn: 'Korea',
      spelledOut: ['ㅎ', '하', '한ㄱ', '한구'],
    },
    {
      name: 'japan',
      displayNameKr: '일본',
      displayNameEn: 'Japan',
      spelledOut: ['ㅇ', '이', '일ㅂ', '읿', '일보'],
    },
    {
      name: 'usa',
      displayNameKr: '미국',
      displayNameEn: 'USA',
      spelledOut: ['ㅁ', '믹', '미구'],
    },
    {
      name: 'vietnam',
      displayNameKr: '베트남',
      displayNameEn: 'Vietnam',
      spelledOut: ['ㅂ', '베', '벹', '베트', '베튼', '베트나'],
    },
  ];

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const filtered = COUNTRIES.filter((country) => {
      const lowercase = (
        country.displayNameKr +
        ' ' +
        country.displayNameEn
      ).toLowerCase();
      return (
        lowercase.includes(inputValue.toLowerCase().trim()) ||
        country.spelledOut.includes(inputValue)
      );
    });
    setSearchableItems(filtered);
  }, [inputValue]);

  useEffect(() => {
    setShowPopover(true);
  }, [inputValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSelectCountry = (country: any) => {
    setSelectedCountry(country);
    setInputValue(`${country.displayNameKr} ${country.displayNameEn}`);
    setShowPopover(false);
    closeModal();

    navigate(`/products/?country=${country.name}`); // navigate to ProductPage.tsx
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: any[];
  }) => {
    return (
      <>
        <p className='block font-semibold text-base'>{heading}</p>
        <div className='mt-3'>
          {items.length ? (
            items.map((item) => {
              return (
                <div
                  className='py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700'
                  onClick={() => handleSelectCountry(item)}
                  key={item.name}
                >
                  <MapPinIcon className='w-5 h-5 text-neutral-500 dark:text-neutral-400' />
                  <span className=''>
                    {item.displayNameKr} {item.displayNameEn}
                  </span>
                </div>
              );
            })
          ) : (
            <div className='py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700'>
              <span className=''>해당 여행지가 없습니다</span>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className='p-5'>
        <span className='block font-semibold text-xl sm:text-2xl'>
          {headingText}
        </span>
        <div className='relative mt-5'>
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-500 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate focus:border-primary-400`}
            placeholder={'여행지를 검색해 보세요'}
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className='absolute right-2.5 top-1/2 -translate-y-1/2'>
            <MagnifyingGlassIcon className='w-5 h-5 text-neutral-700 dark:text-neutral-400' />
          </span>
        </div>
        <div className='mt-7'>
          {showPopover &&
            (inputValue
              ? renderSearchValues({
                  heading: '검색결과',
                  items: searchableItems,
                })
              : renderSearchValues({
                  heading: '인기 여행지',
                  items: COUNTRIES,
                }))}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
