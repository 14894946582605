/**
 * HONEY ESIM 최종 결제 페이지 https://developer.payple.kr/global/payment-window#c48d54f2-ec93-4d55-a72c-e5da2dba6ab5   https://developer.payple.kr/224bffbb-ba39-41bd-9cf2-0c9fe3ed62bf
 */

import { db, doc, getDoc } from 'api/firebase';
import axios from 'axios';
import Label from 'components/Label/Label';
import { generateOrderID } from 'helpers/generateOrderID';
import INT from 'images/INT.png';
import JCB from 'images/jcb.png';
import MASTER from 'images/master-logo.png';
import VISA from 'images/visa.png';
import { Helmet } from 'react-helmet';

import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';

import Input from 'shared/Input/Input';

import { currencyAtom } from 'store/atoms/currency';
import { productAtom, selectedPlanAtom } from 'store/atoms/product';
import { Plan, Product } from 'type/interface';
import { currencyConversion } from 'utils/currencyConversion';

const requestToken = async (): Promise<string[]> => {
  try {
    const { data } = await axios.post(
      'https://us-central1-esim-connect.cloudfunctions.net/requestAuthTokenStatic',
    );
    return [data.access_token, data.payCls];
  } catch (error) {
    return ['error'];
  }
};
export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = '',
}) => {
  const { productName, planId } = useParams();
  const navigate = useNavigate();
  const [currencyState, setCurrencyState] = useAtom(currencyAtom);
  const [productState, setProductState] = useAtom(productAtom);
  const [selectedPlanState, setSelectedPlanState] = useAtom(selectedPlanAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [message, setMessage] = useState('');
  // useEffect(() => {
  //   setCurrencyState('USD'); // USD만 결제 가능
  // }, [setCurrencyState]);

  // Function to validate discount code
  const validateDiscountCode = async () => {
    if (discountPercent > 0 || selectedPlanState.discount_applied) {
      setDiscountPercent(0);
      setMessage('추가 코드 적용은 불가능합니다');
      return;
    }

    try {
      const discountRef = doc(db, 'PROMOTION', discountCode as string);
      const docSnapshot = await getDoc(discountRef);

      if (docSnapshot.exists()) {
        const discountData = docSnapshot.data();

        const currentTime = new Date();
        const validFrom = discountData.valid_from.toDate();
        const validUntil = discountData.valid_until.toDate();

        if (currentTime >= validFrom && currentTime <= validUntil) {
          // Discount code is valid, set discount percentage to 20%
          setDiscountPercent(discountData.discount_percent);
          setMessage(
            `${discountData.discount_percent * 100}% 할인이 적용되었습니다`,
          );
          setSelectedPlanState({
            ...selectedPlanState,
            discount_applied: true,
            discount_code: discountCode,
            price: parseInt(
              (
                (selectedPlanState.price as number) *
                (1 - discountData.discount_percent)
              ).toFixed(2),
            ),
          });
        } else {
          setDiscountPercent(0);
          setMessage('유효기간이 지난 코드입니다');
        }
      } else {
        // Discount code is invalid
        setDiscountPercent(0);
        setMessage('유효하지 않은 코드입니다');
      }
    } catch (error) {
      // Discount code is invalid
      setDiscountPercent(0);
      setMessage('유효하지 않은 코드입니다');
    }
  };

  // Function to handle input change
  const handleInputChange = (event: any) => {
    setDiscountCode(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'PRODUCTS', productName as string);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          // Update the product state without causing a re-render
          setProductState((prevProductState) => {
            const newData = docSnapshot.data() as Product;
            if (prevProductState !== newData) {
              sessionStorage.setItem('productState', JSON.stringify(newData));
              return newData;
            } else {
              return prevProductState;
            }
          });
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (productState.country.length === 0) {
      const storedProductState = sessionStorage.getItem('productState');
      // if product state exists in session storage,
      if (storedProductState) {
        const parsedStoredProductState = JSON.parse(storedProductState);
        // and product name matches the url param,
        if (parsedStoredProductState.product_name === productName) {
          // set product state
          setProductState(parsedStoredProductState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('productState');
          navigate('/404');
        }
      } else {
        // If product state is not found in session storage, fetch it from Firestore
        fetchData();
      }
    }
  }, [productName, navigate, productState, setProductState]);

  useEffect(() => {
    // if product state exists but not selectedPlanstate
    if (productState.country.length && !selectedPlanState.id) {
      // first check session storage
      const storedSelectedPlanState =
        sessionStorage.getItem('selectedPlanState');
      // if storedSelectedPlanState exists
      if (storedSelectedPlanState) {
        const parsedStoredSelectedPlanState = JSON.parse(
          storedSelectedPlanState,
        );
        // check if id matches
        if (parsedStoredSelectedPlanState.id === planId) {
          // then set selected plan state
          setSelectedPlanState(parsedStoredSelectedPlanState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('selectedPlanState');
          navigate('/404');
        }
      } else {
        // if not in session storage, find it from product state
        const plan = productState.plans.find((d) => d.id === planId);
        if (!plan) {
          navigate('/404');
        }
        setSelectedPlanState(plan as Plan);
        sessionStorage.setItem('selectedPlanState', JSON.stringify(plan));
      }
    }
  }, [setSelectedPlanState, selectedPlanState, productState, navigate, planId]);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm();

  // PAY HANDLER 결제창 띄우기
  const onSubmit = async (data: any, event: any) => {
    try {
      // E-TRANSFER
      if (event.nativeEvent.submitter.name === 'etransfer') {
        navigate(
          `/etransfer/${productName}/${planId}?email=${encodeURIComponent(
            data.email.trim().toLowerCase(),
          )}&phone=${encodeURIComponent(data.phone.trim())}`,
        );
        // PAYPLE - CREDIT CARD
      } else {
        setIsLoading(true);
        setCurrencyState('USD'); // USD만 결제 가능
        // 1. get Auth token from payple
        const [accessToken, payCls] = await requestToken();

        // 2. encode custom slug
        const seviceDefine = {
          productState: { ...productState, plans: [] },
          selectedPlanState,
          req_currency: currencyState,
          req_price: currencyConversion(
            selectedPlanState.price as number,
            currencyState,
          ),
          phone: data.phone.trim(),
          email: data.email.trim().toLowerCase(),
        };

        const encodedServiceDefine = btoa(
          encodeURIComponent(JSON.stringify(seviceDefine)),
        );

        // // Decode the base64 string back to object
        // const decodedServiceDefine = JSON.parse(
        //   decodeURIComponent(atob(encodedServiceDefine))
        // );

        // 3. activate payment window
        const pplPaymentObj: any = {
          service_id: 'esim', // [필수] 파트너 ID
          service_oid: generateOrderID(productState, selectedPlanState), // [선택] 주문번호(미지정하는 경우 페이플에서 임의로 지정)
          serviceDefine: encodedServiceDefine, //  BASE64로 활용하기!! [선택] 사용자 정의 파라미터입니다. (길이: 2408)
          comments: selectedPlanState.name_kr, // [필수] 상품명
          totalAmount: currencyConversion(
            selectedPlanState.price as number,
            'USD',
          ), // [필수] 결제 요청금액
          currency: 'USD', // [필수] 통화
          firstName: '', // [선택] 카드소유주 이름
          lastName: '', // [선택] 카드소유주 성
          email: data.email.trim().toLowerCase(), // [선택] 이메일 주소
          phoneNumber: data.phone.trim(),
          resultUrl:
            'https://us-central1-esim-connect.cloudfunctions.net/txResultWebhook', // [필수] 결제결과 반환(Return) URL
          isDirect: 'Y', // [선택] 결제창 호출 다이렉트 여부 ("" | "Y")
          payCls: payCls,
          Authorization: accessToken,
        };

        if (!payCls) {
          delete pplPaymentObj.payCls;
        }

        // TODO:
        window.paypleGpayPaymentRequest(pplPaymentObj);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);

      console.log('e', e);
    }
  };

  const repeatedEmail = watch('email');

  // Function to format the phone number as user types
  const handlePhoneNumberChange = (e: any) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedPhoneNumber = input.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3',
    );
    setValue('phone', formattedPhoneNumber);
  };

  const renderSidebar = () => {
    return (
      <div className='w-full flex flex-col rounded-2xl border dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 border-primary-300'>
        {/* <Helmet>
          <script src={process.env.REACT_PAYPLE_URL}></script>
        </Helmet> */}
        <div className='flex flex-col sm:flex-row sm:items-center'>
          {/* <div className='flex-shrink-0 w-full sm:w-24'>
            <div className=' aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden'>
              <img
                alt=''
                className='absolute inset-0 object-cover'
                width={30}
                height={30}
                src='https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=50&w=50'
              />
            </div>
          </div> */}
          <div className='py-5 px-5 space-y-3'>
            <div>
              <span className='text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1'>
                {selectedPlanState.categoryId === 'dp'
                  ? '데일리 플랜형'
                  : '데이터 용량형'}
              </span>
              <span className='text-2xl font-medium mt-1 block'>
                {`${productState.country_kr} - ${selectedPlanState.capacityId}`}{' '}
                <span className='text-base'>
                  {selectedPlanState.categoryId === 'dp' ? '/일 ' : ''}
                  {selectedPlanState.v_planDescription.includes('unlimited')
                    ? `+ 무제한 ${selectedPlanState.unlimiitedCapacity}`
                    : ''}
                </span>
              </span>
            </div>
            <span className='block  text-base text-neutral-500 dark:text-neutral-400'>
              사용기간:{' '}
              <span className='font-semibold'>{selectedPlanState.days}일</span>
            </span>
            {/* <div className='w-10 border-b border-neutral-200  dark:border-neutral-700'></div> */}
            {/* <StartRating /> */}
          </div>
        </div>
        <div className='flex flex-col space-y-4 pr-4 '>
          {/* <div className='flex items-center ml-auto'>
            {' '}
            <Input
              placeholder='할인코드'
              type='text'
              className={`ml-2 mr-4 max-w-72 ${
                discountPercent ? 'bg-gray-300' : ''
              }`}
              value={discountCode || selectedPlanState.discount_code || ''}
              onChange={handleInputChange}
              disabled={!!discountPercent}
            />
            <ButtonSecondary
              onClick={validateDiscountCode}
              disabled={!!discountPercent}
              className='w-36'
              type='button'
            >
              적용하기
            </ButtonSecondary>
          </div> */}
          <div className='text-xs ml-auto mr-2 text-slate-700'>{message}</div>
          <div className='flex justify-between text-neutral-500 dark:text-neutral-200 text-light text-sm'></div>

          <div className='border-b border-neutral-200 dark:border-neutral-700 ml-2 '></div>
          <span className='text-xs text-red-400 ml-auto'>
            *신용카드는 USD 통화만 결제 가능합니다
          </span>
          <div className='flex justify-between font-semibold pb-4 items-center'>
            <div className='flex justify-start items-center'>
              <NcImage src={INT} className='w-16 mr-2' />
              <NcImage src={VISA} className='w-12 mr-2' />
              <NcImage src={MASTER} className='w-10 mr-2' />
              <NcImage src={JCB} className='w-8' />
            </div>
            <span className='text-neutral-400 font-light text-sm ml-auto mr-1'>
              {currencyState}
            </span>
            <span className='font-semibold text-2xl'>
              {currencyConversion(
                selectedPlanState.price as number,
                currencyState,
              )}
              <span className='text-neutral-400 font-light text-sm'>
                {' '}
                {currencyState === 'KRW' ? '원' : ''}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {' '}
      <Helmet>
        <meta charSet='UTF-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <div className='nc-CheckOutPagePageMain'>
        <main className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'>
          <div className='w-full lg:w-3/5 xl:w-2/3 lg:pr-10'>
            <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
              {/* <h2 className='text-3xl lg:text-4xl font-semibold'>
              주문 상세 내역
            </h2> */}

              {/* 구매시 유의사항 */}

              {/* 구매자 정보 */}
              <div>
                <h3
                  className='text-2xl font-semibold
              '
                >
                  주문자 정보
                </h3>
                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 my-5 hidden lg:block'></div>

                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  {/* 이메일 번호 입력 */}
                  <div className='mt-6'>
                    <div className='space-y-8'>
                      <div className='space-y-1'>
                        <Label>
                          <span className='text-lg'>이메일</span>
                        </Label>
                        <span className='ml-2 text-neutral-500 text-xs'>
                          QR코드 받으실 이메일 주소
                        </span>
                        <Input
                          {...register('email', {
                            onBlur: () => trigger('email'),
                            validate: (value) => {
                              const emailRegex =
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                              if (!value) {
                                return '이메일을 꼭 적어주세요';
                              }
                              if (!emailRegex.test(value)) {
                                return '이메일 형식이 올바르지 않아요';
                              }
                              return true;
                            },
                          })}
                          placeholder='example@email.com'
                          type='email'
                        />
                        {errors.email && (
                          <p className='text-red-500 text-xs ml-3'>
                            {errors.email.message as any}
                          </p>
                        )}
                      </div>

                      <div className='space-y-1'>
                        <Label>
                          <span className='text-lg'>이메일(반복)</span>
                        </Label>
                        <span className='ml-2 text-neutral-500 text-xs'>
                          오타방지를 위해 한번 더 입력해주세요
                        </span>

                        <Input
                          {...register('email_repeat', {
                            onBlur: () => trigger('email_repeat'),
                            validate: (value) => {
                              if (!value) {
                                return '이메일을 꼭 적어주세요';
                              }
                              if (value !== repeatedEmail) {
                                return '이메일이 서로 일치하지 않아요';
                              }
                              return true;
                            },
                          })}
                          placeholder='example@email.com'
                          type='email'
                        />
                        {errors.email_repeat && (
                          <p className='text-red-500 text-xs ml-3'>
                            {errors.email_repeat.message as any}
                          </p>
                        )}
                      </div>
                      {/* 핸드폰 번호 체크 */}
                      <div className='space-y-1'>
                        <Label>
                          <span className='text-lg'>핸드폰 번호</span>
                        </Label>
                        <span className='ml-2 text-neutral-500 text-xs'>
                          핸드폰 번호 (캐나다, 미국)
                        </span>
                        <Input
                          {...register('phone', {
                            onBlur: () => trigger('phone'),
                            onChange: (e) => {
                              handlePhoneNumberChange(e);
                            },
                            validate: (value) => {
                              const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
                              if (!value) {
                                return '핸드폰 번호 10자리를 넣어주세요';
                              }
                              if (!phoneRegex.test(value)) {
                                return '유효하지 않은 번호 형식입니다';
                              }
                              return true;
                            },
                          })}
                          type='tel'
                          placeholder='123-456-7890'
                          maxLength={12} // Adjust as per your requirement
                        />

                        {errors.phone && (
                          <p className='text-red-500 text-xs ml-3'>
                            {errors.phone.message as any}
                          </p>
                        )}
                      </div>
                      <div className='space-y-1'>
                        <div className={`flex text-sm sm:text-base`}>
                          <input
                            {...register('agree', {
                              onBlur: () => trigger('agree'),

                              required: '필수 동의사항입니다.', // Use 'required' for checkbox validation
                            })}
                            type='checkbox'
                            className='focus:ring-action-primary h-6 w-6 text-primary-400 border-primary rounded border-neutral-400 bg-white dark:bg-neutral-700  checked:bg-primary-400 focus:ring-primary-400'
                          />

                          <label
                            htmlFor='agree'
                            className='ml-3.5 flex flex-col flex-1 justify-center'
                          >
                            <span className=' text-neutral-900 dark:text-neutral-100'>
                              환불 규정 및 eSIM 사용 가능 기기 임을 확인했습니다{' '}
                            </span>
                          </label>
                        </div>
                        {errors.agree && (
                          <p className='text-red-500 text-xs ml-3'>
                            {errors.agree.message as any}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* 보더 */}
                  {/* <div className='mt-10 border-b border-neutral-200 dark:border-neutral-700'></div> */}
                  <div className='mt-10'></div>

                  {/* 상품 설명 */}

                  <div className='block flex-grow '>{renderSidebar()}</div>

                  <div
                    id='submit_button'
                    className='flex flex-col sm:flex-row mt-8  justify-end'
                  >
                    {/* <div className='mb-4 lg:mr-4 w-full lg:w-auto mx-2'>
                      <ButtonPrimary
                        // href={'/pay-done'}
                        className={
                          'font-semibold xs:text-xl w-full lg:w-auto mb-2 lg:mb-0'
                        }
                        type='submit'
                        name='etransfer'
                      >
                        <div className='font-light mr-1'>
                          CAD{' '}
                          {currencyConversion(
                            selectedPlanState.price as number,
                            'CAD',
                          )}
                        </div>
                        <div>이트랜스퍼 결제</div>
                      </ButtonPrimary>
                    </div> */}
                    <div className='w-full lg:w-auto mx-2'>
                      <ButtonPrimary
                        disabled={isLoading} //TODO: change it to isLoading
                        loading={isLoading}
                        type='submit'
                        className={'font-semibold xs:text-xl w-full lg:w-auto'}
                      >
                        <div className='font-light mr-1'>
                          USD{' '}
                          {currencyConversion(
                            selectedPlanState.price as number,
                            'USD',
                          )}
                        </div>
                        <div>신용카드 결제</div>
                      </ButtonPrimary>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className='hidden lg:block flex-grow'>{renderSidebar()}</div> */}
        </main>
      </div>
    </>
  );
};

export default CheckOutPagePageMain;
