import G_BD_1 from 'images/user-guide/setup/G_BD_1.png';
import G_BD_2 from 'images/user-guide/setup/G_BD_2.png';
import G_BD_3 from 'images/user-guide/setup/G_BD_3.png';
import G_BD_4 from 'images/user-guide/setup/G_BD_4.png';
import G_BD_5 from 'images/user-guide/setup/G_BD_5.png';
import G_BD_6 from 'images/user-guide/setup/G_BD_6.png';
import G_BD_7 from 'images/user-guide/setup/G_BD_7.png';

import CommonLayout from './CommonLayout';

export default function BDgalaxy() {
  return (
    <CommonLayout
      index='01'
      backtHref=''
      nextHref='/user-guide/setup/bd-galaxy-1'
    >
      <>
        <div>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 1. '}
            <span className='text-bold text-black'>{'설정 > 연결'}</span>
          </h2>
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_1} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 2. '}
            <span className='text-bold text-black'>{'SIM 관리자'}</span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_2} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 3. '}
            <span className='text-bold text-black'>{'eSIM 추가'}</span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_3} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 4. '}
            <span className='text-bold text-black'>{'QR 코드 스캔'}</span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_4} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 5. '}
            <span className='text-bold text-black'>{'QR 코드 스캔'}</span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_5} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 6. '}
            <span className='text-bold text-black'>{'새로운 eSIM 추가'}</span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_6} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        <div className='pt-10'>
          <h2 className='text-2xl font-semibold text-primary-600'>
            {'STEP 7. '}
            <span className='text-bold text-black'>
              {'추가된 eSIM 비활성화 (끄기)'}
            </span>
          </h2>{' '}
          <div className='flex justify-center sm:justify-start'>
            <img src={G_BD_7} className='w-4/5 sm:w-2/3' />
          </div>
        </div>

        {/* Add your content for foo1 */}
      </>
    </CommonLayout>
  );
}
