import React from 'react';
import { Link } from 'react-router-dom';
import LogoPng from './logo-colored.png';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = LogoPng,
  imgLight = LogoPng,
  className = '',
}) => {
  return (
    <Link
      to='/'
      className={`ttnc-logo inline-block text-primary-600 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <Logo1 /> */}
      {/* <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}

      <img src={img} className='hidden md:block w-24 md:w-32' alt='Logo' />
    </Link>
  );
};

export default Logo;
