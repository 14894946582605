// import { StarIcon } from "@heroicons/react/24/solid";
import { AuthorType } from 'data/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';

export interface CardAuthorBoxProps {
  className?: string;
  author: AuthorType;
  index?: number;
}

const CardAuthorBox: FC<CardAuthorBoxProps> = ({
  className = '',
  author,
  index,
}) => {
  const { displayName, href = '/', avatar, starRating } = author;
  return (
    <Link
      to={href}
      className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id='CardAuthorBox'
    >
      {index && (
        <Badge
          className='absolute left-3 top-3'
          color={index === 1 ? 'red' : index === 2 ? 'blue' : 'green'}
          name={`#${index}`}
        />
      )}
      {/* <Avatar
        sizeClass="w-20 h-20 text-2xl"
        radius="rounded-full"
        imgUrl={avatar}
        userName={displayName}
      /> */}
      <div className='flex -space-x-2 overflow-hidden'>
        <img
          className='inline-block h-10 w-10 rounded-full ring-2 ring-white'
          src='https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
          alt=''
        />
        <img
          className='inline-block h-10 w-10 rounded-full ring-2 ring-white'
          src='https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
          alt=''
        />
        <img
          className='inline-block h-10 w-10 rounded-full ring-2 ring-white'
          src='https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80'
          alt=''
        />
        <img
          className='inline-block h-10 w-10 rounded-full ring-2 ring-white'
          src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
          alt=''
        />
      </div>
      <div className='mt-3'>
        <h2 className={`text-base font-medium`}>
          <span className='line-clamp-1'>{displayName}</span>
        </h2>
        <span
          className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          New York
        </span>
      </div>
      <div className='py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center '>
        <span className='text-xs font-medium pt-[1px]'>
          {starRating || 4.9}
        </span>
        {/* <StarIcon className="w-5 h-5 text-amber-500 ml-2 " /> */}
      </div>
    </Link>
  );
};

export default CardAuthorBox;
