const KRW_TO_USD: number = 0.000740035;
const KRW_TO_CAD: number = 0.00100658;

export const currencyConversion = (krw: number, currency: string): string => {
  switch (currency) {
    case 'KRW':
      return krw?.toLocaleString('en-US');
    case 'USD':
      return (krw * KRW_TO_USD).toFixed(2);
    case 'CAD':
      return (krw * KRW_TO_CAD).toFixed(2);

    default:
      return krw?.toLocaleString('en-US');
  }
};
